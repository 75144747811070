export const landingPageTranslations = {
    "landingPage.whyNunmu": "Why Nunmu?",
    "landingPage.contact_management": "Contact Management",
    "landingPage.experience_total_contact": "Experience Total Contact Clarity with Nunmu's Streamlined Hub",
    "landingPage.industry ": "Industry",
    "landingPage.pricing": "Pricing",
    "landingPage.login": "Login",
    "landingPage.free_trial": "Free Trial",
    "landingPage.grow_your_business": "Grow your business with MENA’s most powerful sales automation platform",
    "landingPage.unleash_the_power": "Unleash the power of growth with Nunmu - the cutting-edge technology that powers sales success!",
    "landingPage.start_your_free": "START YOUR FREE TRIAL NOW",
    "landingPage.no_credit": "No credit card required・Free for 30 days",
    "landingPage.platform_features": "Platform Features",
    "landingPage.empowering_your": "Empowering Your Business with Streamlined Solutions and Integrations",
    "landingPage.arbic_support": "Arabic Support",
    "landingPage.full_arabic": "Full Arabic support, allows for a native language interface and tailored content for users.",
    "landingPage.industry_driven": "Industry Driven",
    "landingPage.pre_configured": "Pre-configured customizations tailored to specific industries, streamlining the setup process and optimizing it for your specific use case.",
    "landingPage.pre_built": "Pre-Built Dashboards",
    "landingPage.custom_manager": "Custom manager reports and dashboards for quick, customizable insights to empower decision makers with actionable data.",
    "landingPage.integrations": "Integrations",
    "landingPage.empower_your_business": "Empower your business by connecting with other systems and vendors for enhanced functionality and data.",
    "landingPage.integrate_with_your": "Integrate with your favorite apps.",
    "landingPage.we_agonise": "We agonise over the right abstractions so your teams don’t need to stitch together disparate systems or spend months integrating payments functionality.",
    "landingPage.explore_our": "EXPLORE OUR MARKETPLACE",
    "landingPage.unlock_the_full": "Unlock the Full Potential of Your Business with NUNMU's powerful Features",
    "landingPage.all_in_one_solution": "All-in-one solution that helps teams manage, track, and streamline their sales deals!",
    "landingPage.easy_to_use": "Easy to use web application",
    "landingPage.boost_sales": "Boost sales with deal automation",
    "landingPage.increased_efficiency": "Increased efficiency and management",
    "landingPage.empower_your": "Empower your platform with Nunmu's Open API technology",
    "landingPage.read_the_docs": "READ THE DOCS",
    "landingPage.try_nunmu_now": "Try Nunmu Now!",
    "landingPage.unleash_your_business": "Unleash Your Business Growth with Seamless Sales Automation",
    "landingPage.first_name": "First Name",
    "landingPage.last_name": "Last Name",
    "landingPage.email": "Email",
    "landingPage.mobile_number": "Mobile Number",
    "landingPage.company_name": "Company Name",
    "landingPage.submit": "SUBMIT",
    "landingPage.saas": "SaaS",
    "landingPage.deal_tracking": "Deal Tracking",
    "landingPage.track_every_step": "Track Every Step of Your Sales Process with Ease",
    "landingPage.manage_tasks": "Manage Tasks",
    "landingPage.transform_your_task": "Transform Your Task Management with a Collaborative Platform",
    "landingPage.connect_automate": "Connect, Automate, Grow: Seamless Integrations for Effortless Business Efficiency",
    "landingPage.streamline_your_business": "Streamline your business with industry-specific templates for quick and efficient customization",
    "landingPage.sales_insights": "Sales Insights",
    "landingPage.mazimize_your_sales": "Maximize Your Sales Potential with Real-Time Insights & Reporting Solutions",
    "landingPage.software_as_a_service": "Software as a Service companies, with focus on subscriptions.",
    "landingPage.business_services": "Business Services",
    "landingPage.comapnies_that_profide": "Companies that provide a services such as consulting, human resources, and IT.",
    "landingPage.financial_services": "Financial Services",
    "landingPage.such_as_banks": "Such as banks, insurance, micro-finance and tax consulting.",
    "landingPage.transport_shipping": "Transport & Shipping",
    "landingPage.companies_involved_in": "Companies involved in the transportation, logistics and shipping industry.",
    "landingPage.e_commerce": "E-Commerce",
    "landingPage.b2b_or_b2c": "B2B or B2C that sell physical products through an online store.",
    "landingPage.media_agencies": "Media & Agencies",
    "landingPage.tv_or_radio_operators": "TV or Radio operators, as well as advertising and social media agencies.",
    "landingPage.transform_your_contact": "Transform Your Contact Management with Nunmu",
    "landingPage.nunmu_streamlines_every": "Nunmu streamlines every aspect of your contact management - centralizing meetings, calls, files, and notes for a complete relationship overview.",
    "landingPage.streamline_your_relationships": "Streamline Your Relationships: Manage Every Contact Effortlessly with 360° Visibility",
    "landingPage.market_place": "Market Place",
    "landingPage.zoominfo_has_a_proven": "Zoominfo has a proven track record of helping our customers reach their most important goal: profitable growth to the bottom line.",
    "landingPage.unlock_limitless_growth": "Unlock Limitless Growth with Nunmu's Affordable Plans",
    "landingPage.experience_the_full": "Experience the full capabilities of Nunmu risk-free with our no-obligation trial. Sign up now and start optimizing your sales process today!",
    "landingPage.most_popular": "Most popular",
    "landingPage.user": "user",
    "landingPage.whats_included": "Whats Included?",
    "landingPage.donot_hesitate_to_reach": "Don't hesitate to reach out - our support team is here to guide you in selecting the perfect plan for your needs",
    "landingPage.live_chat": "LIVE CHAT",
    "landingPage.contact_sales": "CONTACT SALES",

    "landingPage.overview": "Overview",
    "landingPage.features": "Features",
    "landingPage.careeres": "Careers",
    "landingPage.help": "Help",
    "landingPage.privacy": "Privacy",

    "landingPage.developedByNunmu": "Developed by Nunmu. All rights reserved",
    "landingPage.terms": "Terms",
    "landingPage.cookies": "Cookies",

    "landingPage.deal_tracking_feature": "FEATURES",
    "landingPage.deal_tracking_feature_brief": "Nunmu helps sales teams keep track of their deal cycle, from winning new business to closing deals",
    "landingPage.deal_tracking_feature_1_title": "Visualize Sales",
    "landingPage.deal_tracking_feature_1_brief": "Use the visual pipeline feature to efficiently track prospects through your sales funnel, and keep your team organized and focused on closing deals.",
    "landingPage.deal_tracking_feature_2_title": "Customize Deal Flow",
    "landingPage.deal_tracking_feature_2_brief": "Customize your sales process by creating deal stages to match your needs, and easily track the progress of each deal.",
    "landingPage.deal_track_footer_title": "Achieve Your Sales Targets",
    "landingPage.deal_track_footer_brief": "Track your sales targets effortlessly with Nunmu. Set, assign and track targets for individual team members and monitor their progress, providing insights and feedback to optimize sales performance.",
    "landingPage.industry_driven_title": "Accelerate Your Setup with Customized Templates for Your Industry",
    "landingPage.industry_driven_brief": "Nunmu is the only sales management platform that is industry-driven, meaning it comes pre-customized with templates that cater to your specific industry needs. This allows you to accelerate your setup phase and get started with sales management quickly.",
    "landingPage.sales_insight": "Get the data you need to optimize your sales performance",
    "landingPage.sales_insight_feature": "FEATURES",
    "landingPage.sales_insight_feature_brief": "Nunmu provides you with real-time data and analytics that help you measure and improve your sales performance. Our powerful analytics engine gives you visibility into your sales pipeline, allowing you to make data-driven decisions that can increase your sales revenue.",
    "landingPage.task_management_header_title": "Manage Tasks with Ease",
    "landingPage.task_management_header_brief": "Streamline Your Workflow and Never Miss a Deadline",
    "landingPage.task_management_title": "With Nunmu, you can easily create, assign, and track tasks to ensure that your team stays on top of their deadlines and delivers quality work.",
    "landingPage.task_management_1_title": "Create Tasks Quickly",
    "landingPage.task_management_1_brief": "Nunmu's intuitive interface allows you to create new tasks in just a few clicks. Simply input the task details, assign it to a team member, and set the due date.",
    "landingPage.task_management_2_title": "Set Task Priorities",
    "landingPage.task_management_2_brief": "Prioritizing tasks can be a challenge, but with Nunmu's Task Management, you can easily mark a task as high, medium, or low priority. This ensures that your team is focusing on the most important tasks first.",
    "landingPage.task_management_3_title": "Monitor Progress with Task Reports",
    "landingPage.task_management_3_brief": "With Nunmu's Task Management, you can easily track the progress of your team's tasks and generate reports to see how you're doing. This helps you identify areas for improvement and make data-driven decisions to enhance your workflow.",

    "landingPage.get_started_with_nunmu_today_and_take": "Get started with Nunmu today and take advantage of our industry-driven customization to optimize your sales process and accelerate your business growth.",
    "landingPage.deal_tracking_banner": "Maximize Your Sales Potential with Nunmu's Deal Tracking Feature"
}
