import { landingPageTranslations } from "../../../translation";

export const FeatureModul = [
  {
    id: "feature-1",
    image: "images/bitmap/Bitmap.svg",
    number: "01",
    text: "Easy to use web application",
    key:  "landingPage.easy_to_use"
  },
  {
    id: "feature-2",
    image: "images/bitmap/Bitmap-1.svg",
    number: "02",
    text: "Boost sales with deal automation",
    key:  "landingPage.boost_sales"
  },
  {
    id: "feature-3",
    image: "images/bitmap/Bitmap-2.svg",
    number: "03",
    text: "Increased efficiency and management",
    key:  "landingPage.increased_efficiency"
  },
];
