import React from 'react'
import ReactLoading from 'react-loading';
function AppButton({btnProp={},children,disabled = false,haswhite = false,className = "primary", onClick, title, isLoading = false,style={}}) {
    console.log(btnProp["data-source"], title)
    const loadingColor = className === "white" || haswhite?"#020230": "white"
    return (
        <button 
            className = {`button ${className}`} 
            onClick = {onClick}
            disabled = {isLoading || disabled}
            style = {style}
            data-source = {btnProp["data-source"]? btnProp["data-source"]: ""}
            // {...btnProp}
        >
            {
                isLoading?
                    <ReactLoading type={"cylon"} color={loadingColor} height = {30}/>
                :
                <>
                    <div>{title}</div>
                    {
                        (children) && title?
                        <div style={{width: 7}}></div>
                        :
                        <></>
                    }
                    {children}
                </>
            }
        </button>
        
    )
}

export default AppButton