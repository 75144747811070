import React from 'react'
import { useSelector } from 'react-redux';
import AppSearchInput from '../../../Components/AppSearchInput'
import { landingPageTranslations } from '../../../translation'

export default function Marketplace() {
  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  return (
    <section className='market-place'>
        <div className='section-header'>
            <h2 className='white'>{landingPageTranslations["landingPage.market_place"] || "Market Place"}</h2>
            <p className='white'>{landingPageTranslations["landingPage.zoominfo_has_a_proven"] || "Zoominfo has a proven track record of helping our customers reach their most important goal: profitable growth to the bottom line."}</p>
        </div>
        <AppSearchInput
            placeholder={"Search app name or category..."}
            style = {{minWidth: 360}}
        />
    </section>
  )
}
