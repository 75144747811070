import React from 'react'
import { useSelector } from 'react-redux';
import ContactForm from '../../Components/ContactForm';
import Trial from '../Component/Trial';
import { useRef } from 'react';
import { useState } from 'react';
import AppButton from '../../Components/AppButton';
import HrefLangHelmet from '../../Components/HrefLangHelmet';
import mixpanel from 'mixpanel-browser';

const SalesInsight = ({toRoute}) => {

    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}
    const formRef = useRef(null)
    const [isOpenForm, setIsOpenForm] = useState(false)

    return (
        <>
        <HrefLangHelmet toRoute = {toRoute}/>
        <div className='deal-tracking'>

            <div className="banner-header">
                <div className="auto__container">
                    <div className='section-header'>
                        <h2 style={{textAlign: 'center'}} className="white">{landingPageTranslations["landingPage.sales_insight"] || "Get the data you need to optimize your sales performance"}</h2>
                        <AppButton 
                            onClick = {()=>{
                                mixpanel.track("free trial clicked", {
                                    "language": "en_US",
                                    "section": "banner",
                                    "page": "sales-insight"
                                });
                                window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
                            }}
                            className='white' 
                            title={landingPageTranslations["landingPage.get_started"] || "Get Started"} style={{margin: "auto"}} />
                    </div>
                    <img src={process.env.PUBLIC_URL + "/images/sales_banner.jpg"} alt="logo" />
                </div>
            </div>

            <div className='deal-tracking-body'>
                <div className="auto__container">
                    <div className='section-header'>
                        <h6>{landingPageTranslations["landingPage.sales_insight_feature"] || "FEATURES"}</h6>
                        <h3 style={{color: "black"}}>{landingPageTranslations["landingPage.sales_insight_feature_brief"] || "Nunmu provides you with real-time data and analytics that help you measure and improve your sales performance. Our powerful analytics engine gives you visibility into your sales pipeline, allowing you to make data-driven decisions that can increase your sales revenue."}</h3>
                    </div>
                    <div className='deal-tracking-body-content'>
                        <div className='image-text-layout'>
                            <div className='image-text-layout-image-view'>
                                <img src={process.env.PUBLIC_URL + "/images/sb1.jpg"} alt="logo" />
                            </div>
                        </div>
                        <div className='image-text-layout'>
                            <div className='image-text-layout-image-view'>
                                <img src={process.env.PUBLIC_URL + "/images/sb2.jpg"} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class= "manage-relationship">
            <ContactForm page={"sales-insight"} isOpen ={isOpenForm} formRef= {formRef} btnText={isOpenForm === true? "": isOpenForm} source={isOpenForm === true? "": isOpenForm} />
            </div>
            <Trial page='sales-insight' toRoute = {toRoute}  setIsOpenForm = {()=>{
                setIsOpenForm(landingPageTranslations["landingPage.submit_request"] ||  "Submit Request")
                if(formRef){
                    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
            }}/>

        </div>
        </>
    )
}

export default SalesInsight
