
const baseURL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION

export const getPricingCardsApi = async({lang})=>{
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    }
    const response = await fetch(`${baseURL}/site/default/pricing?lang=${lang}`, requestOptions)
    return response.text()
  }