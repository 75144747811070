import React from 'react'
import { useSelector } from 'react-redux';
import AppButton from '../../../Components/AppButton'
import { landingPageTranslations } from '../../../translation'
import mixpanel from 'mixpanel-browser';

export default function ContactManagementHeader({toRoute}) {
  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  return (
    <section className="intro favorite contact-management-header">
      <div className="auto__container">
        <div className="favorite__inner">
          <div className="favorite__inner-content section-header">
            <h2 className="white">{landingPageTranslations["landingPage.transform_your_contact"] || "Transform Your Contact Management with Nunmu"}</h2>
            <p className="white">{landingPageTranslations["landingPage.nunmu_streamlines_every"] || "Nunmu streamlines every aspect of your contact management - centralizing meetings, calls, files, and notes for a complete relationship overview."}</p>
            
            <AppButton 
              onClick = {()=>{
                mixpanel.track("free trial clicked", {
                  "language": "en_US",
                  "section": "Banner",
                  "page": "contact-management"
                });
                window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
              }}
              className="white" title={landingPageTranslations["landingPage.start_your_free"] || "Start Your Free Trial Now"}>
              {/* <img
                src={process.env.PUBLIC_URL + "/images/chevron-right.png"}
                alt="svgs"
              /> */}
            </AppButton>
            <p className='white'>{landingPageTranslations['landingPage.no_credit'] || "No credit card required • Free for 30 days No commitment"}</p>
          </div>
          <div className="favorite__inner-image">
            <img
              src={process.env.PUBLIC_URL + "/images/contact-management.png"}
              alt="svgs"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
