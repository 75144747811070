import React from 'react'
import { useSelector } from 'react-redux';
import AppButton from '../../../Components/AppButton'
import { landingPageTranslations } from '../../../translation'
import mixpanel from 'mixpanel-browser';

export default function ManageRelationship({toRoute}) {
  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  return (
    <div className='manage-relationship' style={{backgroundColor: '#fff'}} >
        <div className='manage-relationship__inner'>
            <div className='section-header'>
                <h2>{landingPageTranslations["landingPage.streamline_your_relationships"] || "Streamline Your Relationships: Manage Every Contact Effortlessly with 360° Visibility"}</h2>
                <p>{landingPageTranslations['landingPage.try_nunmu_now'] || `Try Nunmu Now!`}</p>
                <p>{landingPageTranslations['landingPage.no_credit'] || "No credit card required • Free for 30 days No commitment"}</p>
            </div>
            <AppButton
                title={landingPageTranslations["landingPage.start_your_free"] || "Start your free trial now"}
                onClick = {()=>{
                  mixpanel.track("free trial clicked", {
                    "language": "en_US",
                    "section": "manage-relationship",
                    "page": "contact-management"
                  });
                  window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
                }}
            />
        </div>
    </div>
  )
}
