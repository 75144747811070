import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { staticPageSlug } from "../../Helper/constants";
import { getDefaultPage } from "../../redux/features/staticPage/staticPageSlice";
import { useRef } from "react";
import AppButton from '../../Components/AppButton'
import HrefLangHelmet from "../../Components/HrefLangHelmet";
import ContactForm from '../../Components/ContactForm'

export default function StaticPage({toRoute}) {
    const {pageSlug = ""} = useParams()

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {lang,translations} = useSelector(state=> state.language)
    const {data} = useSelector(state=> state.staticPage)
    const pageContent = data[`${pageSlug}`]  || {}
    const landingPageTranslations = translations?.landingPage || {}
    const pageRef = useRef(null)

  useEffect(() => {
    pageRef.current.scrollIntoView({ behavior: 'smooth' })
    if(staticPageSlug.includes(pageSlug)){
        setPageContent()
    }else{
        navigate(`/${toRoute}`)
    }
  }, [lang,pageSlug])
  
  const setPageContent = ()=>{
    dispatch(getDefaultPage({
      slug: `${pageSlug}`,
      lang: lang
    }))
  }
  

  return (
    <>
    <HrefLangHelmet toRoute = {toRoute}/>
    <div className = {"static-page"} ref={pageRef}>
        <div className="auto__container">
            <div className="static-page-content">
                <h1 className="static-page-heading">{pageContent?.title}</h1>
                <div dangerouslySetInnerHTML={{__html: pageContent?.content}} />
                {
                  pageSlug === "contact"?
                    <>
                    <AppButton
                      title={landingPageTranslations["landingPage.live_chat"] || "LIVE CHAT"}
                      onClick = {()=>{window?.groove?.widget?.open()}}
                      style={{marginTop: 20}}
                    />
                    <ContactForm source="Submit" btnText={landingPageTranslations["landingPage.submit"] ||  "Submit"} isOpen = {true} showHeader = {false} />
                    </>
                  :
                  <></>
                }
            </div>
        </div>
    </div>
    </>
  );
}
