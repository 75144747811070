import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
// import {useNavigate} from 'react-router-dom'
import { angleBottom, business, businessServiceSvg, chartSvg, deals, ecommerceSvg, financeSvg, industrySvg, integrationsSvg, mediaSvg, saasSvg, systems, transportSvg, users } from "../../assets/svgs";
import { LANGUAGE_TYPE } from "../../Helper/constants";
import mixpanel from 'mixpanel-browser';
import { getCookie, setCookies } from "../../Helper/functions";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, getTranslationHandler } from "../../redux/features/language";
import AppButton from "../../Components/AppButton";
// import { landingPageTranslations } from "../../translation";

export default function Header({toRoute,onLangChange}) {

  const [menu, setMenu] = useState(false);
  
  const {status, page, translations, lang} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {};
  const [langDropdown, setLangDropdown] = useState(lang == LANGUAGE_TYPE.en? "en": "العربية");
  const dispatch = useDispatch()

  // const navigate = useNavigate();  
  const availableLangs = ["en", "العربية"];
  
  
  
  useEffect(() => {
  
    if (langDropdown === "العربية") {
      onLangChange("ar")
      setCookies("language",LANGUAGE_TYPE.ar,10000,`.${process.env.REACT_APP_DOMAIN}`)
      dispatch(changeLanguage(LANGUAGE_TYPE.ar))
      document.body.classList.add("arabic")
    } else {
      onLangChange("en")
      dispatch(changeLanguage(LANGUAGE_TYPE.en))
      setCookies("language",LANGUAGE_TYPE.en,10000,`.${process.env.REACT_APP_DOMAIN}`)
      document.body.classList.remove("arabic")
    }

  }, [langDropdown])
  
  const closeMenu = (e)=>{
      // hideElement(e)
      document.body.classList.remove("active")
      setMenu(false)
      handleSubmenuClick(e)
  }

  
  const menuClose = React.useCallback((e) => {
    
    const target = e.target;
    if (target === document.querySelector(".nav")) {
      document.body.classList.remove("active")
      document.body.removeEventListener("click", menuClose)
      setMenu(false)
    }

  }, [])

  function hideElement(e) {
    console.log(e)
    const parentNavLink = e.currentTarget.closest(".subnav-content");
    if(parentNavLink){
      parentNavLink.style.setProperty("visibility", "hidden", "important");
    }
    setTimeout(() => {
      parentNavLink.style.removeProperty("visibility");
    }, 1000);
    // var elements = document.getElementsByClassName("subnav-content");
    // for (var i = 0; i < elements.length; i++) {
    //   // elements[i].style.display = "none";
    //   elements[i].style.setProperty("visibility", "hidden", "important");

    // }
  }

  useEffect(() => {
    if (menu) {
      document.body.addEventListener("click", menuClose)
      document.body.classList.add("active")
    }
  }, [menu])

  /**
   * Get Translation from server
   */
  useEffect(() => getTranslation(), [lang])
  const getTranslation = ()=>{
    dispatch(getTranslationHandler({
      category: 'landingPage',
      lang: lang
    }))
  }

  function handleSubmenuClick(event) {
    const parentNavLink = event.currentTarget.closest(".subnav");
    if (parentNavLink) {
      parentNavLink.classList.remove("hover-effect");
    }
    // event.preventDefault();
  }

  function handleNavlinkMouseEnter(event) {
    event.currentTarget.classList.add("hover-effect");
  }

  function handleNavlinkMouseLeave(event) {
    const navLink = event.currentTarget;
    if (navLink.classList.contains("hover-effect")) {
      navLink.classList.remove("hover-effect");
    }
  }
    
  return (
    <header className="header" id="header">
      <div className="auto__container">
        <div className="header__inner">
          <NavLink onClick={closeMenu} to={toRoute} className="header__inner-logo"><img src={process.env.PUBLIC_URL + "/images/logoLight.svg"} alt="logo" /></NavLink>
          <nav className={"nav " + (menu ? "active" : "")} id="menu">
            <div className="nav__inner">
              <div className="row">
                <div className="subnav" 
                  onMouseEnter={handleNavlinkMouseEnter}
                  onMouseLeave={handleNavlinkMouseLeave}
                >
                  <span className="nav__inner-link" >{landingPageTranslations["landingPage.whyNunmu"] || "Why Nunmu?"} {angleBottom}</span>
                  <div className="subnav-content" >
                    <div className= {"subnav-inner"}>
                      <NavLink 
                        onClick={closeMenu} 
                        to={`${toRoute}/why-nunmu/contact-management`} className="nav__inner-link"
                      >
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.contact_management"] || "Contact Management"}
                          desc = {landingPageTranslations["landingPage.experience_total_contact"] || "Experience Total Contact Clarity with Nunmu's Streamlined Hub"}
                          icon = {users}
                        />

                      </NavLink>
                      <NavLink onClick={closeMenu}  to={`${toRoute}/why-nunmu/deal-tracking`} className="nav__inner-link">
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.deal_tracking"] || "Deal Tracking"}
                          desc = {landingPageTranslations["landingPage.track_every_step"] || "Track Every Step of Your Sales Process with Ease"}
                          icon = {deals}
                        />
                      </NavLink>
                      <NavLink onClick={closeMenu}  to={`${toRoute}/why-nunmu/task-management`} className="nav__inner-link">
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.manage_tasks"] || "Manage Tasks"}
                          desc = {landingPageTranslations["landingPage.transform_your_task"] || "Transform Your Task Management with a Collaborative Platform"}
                          icon = {systems}
                        />
                      </NavLink>
                      <NavLink onClick={closeMenu}  to={`${toRoute}/why-nunmu/sales-insight`} className="nav__inner-link">
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.sales_insights"] || "Sales Insights"}
                          desc = {landingPageTranslations["landingPage.mazimize_your_sales"] || "Maximize Your Sales Potential with Real-Time Insights & Reporting Solutions"}
                          icon = {chartSvg}
                        />
                      </NavLink>
                      <NavLink onClick={closeMenu}  to={`${toRoute}/why-nunmu/industry-driven`} className="nav__inner-link">
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.industry_driven"] || "Industry Driven"}
                          desc = {landingPageTranslations["landingPage.streamline_your_business"] || "Streamline your business with industry-specific templates for quick and efficient customization"}
                          icon = {industrySvg}
                        />
                      </NavLink>
                      {/* <SubmenuCard
                        title = {landingPageTranslations["landingPage.integrations"] ||  "Integrations"}
                        desc = {landingPageTranslations["landingPage.connect_automate"] || "Connect, Automate, Grow: Seamless Integrations for Effortless Business Efficiency"}
                        icon = {integrationsSvg}
                      /> */}
                    </div>
                  </div>

                </div>
                <div className="subnav"
                  onMouseEnter={handleNavlinkMouseEnter}
                  onMouseLeave={handleNavlinkMouseLeave}
                >
                    <span className="nav__inner-link" >{landingPageTranslations["landingPage.industry "] || "Industry"} {angleBottom}</span>
                    <div className="subnav-content">
                      <div className= {"subnav-inner"}>
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.saas"] ||"SaaS"}
                          desc = {landingPageTranslations["landingPage.software_as_a_service"] || "Software as a Service companies, with focus on subscriptions."}
                          icon = {saasSvg}
                        />
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.business_services"] || "Business Services"}
                          desc = {landingPageTranslations["landingPage.comapnies_that_profide"] || "Companies that provide a services such as consulting, human resources, and IT."}
                          icon = {businessServiceSvg}
                        />
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.financial_services"] || "Financial Services"}
                          desc = {landingPageTranslations["landingPage.such_as_banks"] || "Such as banks, insurance, micro-finance and tax consulting. "}
                          icon = {financeSvg}
                        />
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.transport_shipping"] || "Transport & Shipping"}
                          desc = {landingPageTranslations["landingPage.companies_involved_in"] || "Companies involved in the transportation, logistics and shipping industry."}
                          icon = {transportSvg}
                        />
                        {/* <SubmenuCard
                          title = {"Industry Driven"}
                          desc = {"Streamline your business with industry-specific templates for quick and efficient customization"}
                          icon = {industrySvg}
                        /> */}
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.e_commerce"] ||"E-Commerce"}
                          desc = {landingPageTranslations["landingPage.b2b_or_b2c"] || "B2B or B2C that sell physical products through an online store."}
                          icon = {ecommerceSvg}
                        />
                        <SubmenuCard
                          title = {landingPageTranslations["landingPage.media_agencies"] || "Media & Agencies"}
                          desc = {landingPageTranslations["landingPage.tv_or_radio_operators"] || "TV or Radio operators, as well as advertising and social media agencies."}
                          icon = {mediaSvg}
                        />
                      </div>
                    </div>
                </div>
                <NavLink to={`${toRoute}/pricing`} onClick={closeMenu} className="nav__inner-link">{landingPageTranslations["landingPage.pricing"] || "Pricing"}</NavLink>
                {/* <NavLink to="/arabic" className="nav__inner-link">{commonTranslation["common.arabic"] || "Arabic"}</NavLink> */}

              </div>
              <div className="row nav__actions">
                <Link to={`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/login`} className="button">{landingPageTranslations["landingPage.login"] || "Login"}</Link>
                {/* <AppButton
                  title={landingPageTranslations["landingPage.login"] || "Login"}
                  onClick={()=>navigate(`${process.env.REACT_APP_REDIRECT_URL}/login`)}
                /> */}
                <AppButton 
                  title={landingPageTranslations["landingPage.free_trial"] || "Free Trial"}
                  className="button skyBlue"
                  onClick = {()=>{
                    mixpanel.track("free trial clicked", {
                      "language": "en_US",
                      "section": "navigation bar",
                    });
                    window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
                    
                  }}
                />
                <div className="nav__inner-lang">
                  <div className="nav__inner-lang-title">
                    <img src={process.env.PUBLIC_URL + "/images/icons/global.svg"} alt="global" />
                    {langDropdown}
                    {/* <span></span> */}
                  </div>
                  <div className="nav__inner-lang-content">
                    {availableLangs.map((item, index) => {
                      return item !== langDropdown ? (
                        <div
                          key={index}
                          className="nav__inner-lang-content-link"
                          onClick={() => {setLangDropdown(item);closeMenu();}}
                        >
                          {item}
                        </div>
                      ) : (
                        <div key={index}></div>
                      );
                    })}
                  </div>

                </div>
              </div>
            </div>
          </nav>

          
          <div
            className={"burger " + (menu ? "active" : "")}
            id="menuBtn"
            onClick={() => {
              setMenu(!menu);
            }}
          ></div>

        </div>
      </div>
    </header>
  );
}


const SubmenuCard = ({icon, title, desc})=>{
  return(
    <div className="submenu-card-container row">
      <span>
        {icon}
      </span>
      <div>
        <h5>{title}</h5>
        <p>{desc}</p>
      </div>
    </div>
  )
}