import React from "react";
import { useSelector } from "react-redux";
import { angleRight } from "../../../assets/svgs";
import AppButton from "../../../Components/AppButton";
import Lottie from "lottie-react";
import CodeAnimation from '../../../assets/code.json'

export default function Api() {

  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}

  return (
    <section className="api">
      <div className="auto__container">
        <div className="api__inner">
          <div className="api__inner-content">
            <div className="section-header">
                <label>{landingPageTranslations["landingPage.designed_for_dev"] || "Designed for developers"}</label>
                <h2 className="white">{landingPageTranslations["landingPage.empower_your"] || "Empower your platform with Nunmu's Open API technology"}</h2>
                <p className="white">{landingPageTranslations["landingPage.we_agonise"] || "We agonise over the right abstractions so your teams don’t need to stitch together disparate systems or spend months integrating  functionality."}</p>
            </div>
            
            <AppButton 
              className="white" title={landingPageTranslations["landingPage.read_the_docs"] || "Read the docs"}
              onClick={()=>window.open(process.env.REACT_APP_API_DOC,"_blank")}
            >
              <span className="flip">{angleRight}</span>
            </AppButton>
          </div>
          <div className="api__inner-image">
            <Lottie 
              animationData={CodeAnimation} loop={true} style={{height: 500}}
            />
            {/* <img
              src={process.env.PUBLIC_URL + "/images/api.png"}
              alt="api"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
