import React from 'react'
import AppSearchInput from '../../../Components/AppSearchInput'

export default function Activites() {
  return (
    <section className='activities-sections'>
        <div className="auto__container">
            <div className='activities-sections__inner row'>
                <aside>
                    <AppSearchInput placeholder={"Search..."}/>
                    <div className='side-section'>
                        <label>Discover</label>
                        <ul>
                            <li>All Section</li>
                            <li>Most Popular</li>
                            <li>Most Popular</li>
                        </ul>
                    </div>
                    <div className='side-section'>
                        <label>Categories</label>
                        <ul>
                            <li>All Section</li>
                            <li>Most Popular</li>
                            <li>Most Popular</li>
                        </ul>
                    </div>
                </aside>
                
                <div className='activities-sections-body'>
                    <div className='categories-cards'>
                        <label>Activites</label>
                        <div className='row'>
                            {[1,2,3].map(()=>{
                                return(
                                    <div className='categories-card'>
                                        <div className='categories-card-bg-img'>
                                            
                                        </div>
                                        <div className='categories-card__inner'>
                                            <h6>HubSpot</h6>
                                            <p>CRM, Marketing Automation, CRM Automation</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='categories-cards'>
                        <label>New Apps</label>
                        <div className='row'>
                            {[1,2,3].map(()=>{
                                return(
                                    <CardLong/>
                                )
                            })}
                        </div>
                    </div>
                    <div className='categories-cards'>
                        <label>All Apps</label>
                        <div className='row'>
                            {[1,2,3,4,5,6,7,8,9,0,11,12,13,14,15,16,17].map(()=>{
                                return(
                                    <CardLong/>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}


const CardLong = ()=>{
    return(
        <div className='categories-card-long row'>
            <div className='categories-card-long-img'>
                
            </div>
            <div className='categories-card-long__inner'>
                <h6>HubSpot</h6>
                <p>CRM, Marketing Automation, CRM Automation</p>
            </div>
        </div>
    )
}