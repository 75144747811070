import React from 'react'
import { useSelector } from 'react-redux'
import AppButton from '../../../Components/AppButton'
import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
// import { landingPageTranslations } from '../../../translation'

function Intro({toRoute}) {

  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}

  
  return (
    
    <section className="intro contact-management-header">
        <div className='auto__container'>
          <div className='api__inner'>
            <div className='api__inner-content'>
              <label>{landingPageTranslations['landingPage.progress_smarter'] || "Progress SMARTER, NOT HARDER"}</label>
              <h2>{landingPageTranslations['landingPage.grow_your_business'] || "Grow your business with MENA’s most powerful sales automation platform"}</h2>
              <p>{landingPageTranslations['landingPage.unleash_the_power'] || "Unleash the power of growth with Nunmu - the cutting-edge technology that powers sales success!"}</p>
              <AppButton
                title={landingPageTranslations['landingPage.start_your_free'] || "START YOUR FREE TRIAL NOW"}
                className = {"primary"}
                onClick = {()=>{
                  mixpanel.track("free trial clicked", {
                    "language": "en_US",
                    "section": "banner",
                    "page": "landing page"
                  });
                  window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
                }}
              />
              <p>{landingPageTranslations['landingPage.no_credit'] || "No credit card required • Free for 30 days No commitment"}</p>
            </div>
            <div className='api__inner-image'>
              <img src={process.env.PUBLIC_URL + "/images/intro.png"} alt="logo" />
            </div>
          </div>
        </div>
    </section>
  )
}

export default Intro