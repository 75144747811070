import React from 'react'
import Activites from './Activities'
import Marketplace from './Marketplace'
import HrefLangHelmet from '../../Components/HrefLangHelmet'

export default function AppMarket({toRoute}) {
  return (
    <>
      <HrefLangHelmet toRoute = {toRoute}/>
        <Marketplace/>
        <Activites/>
    </>
  )
}
