import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { landingPageTranslations } from "../../../translation";

import { PlatformModul } from "./PlatformModul";

export default function Platform() {
  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  
  return (
    <section className="platform-section">
      <div className="auto__container">
        <div className="platform-section-heading-container">
          <h2 style={{color: "#111827"}}>{landingPageTranslations["landingPage.platform_features"] || "Platform Features"}</h2>
          <h6 className = {"infoTitle"}>{landingPageTranslations["landingPage.empower_your_business"] || "Empowering Your Business with Streamlined Solutions and Integrations"}</h6>
        </div>
        <div className = {"platform-section-cards"}>
            {
                PlatformModul.map(card=>{
                    const translationHeading = landingPageTranslations[card?.key] || card.name
                    const translationText = landingPageTranslations[card.textKey] || card.text
                    return(
                        <div key={card.id} className = {"platform-section-card"}>
                            <div style={{display: 'flex', alignItems:'center'}}>
                                <div className = {"platform-icon-bg"}>
                                    <img src={card.image} alt="bitmap" />
                                </div>
                                <div className ={"platform-section-heading gradient"}>{translationHeading}</div>
                            </div>
                            <h6 className ={"platform-section-text"}>{translationText}</h6>
                            {/* <div style={{display: 'flex', alignItems:'center'}}>
                                <a className = {"generalText gradient"}>{card.linkText}</a>
                                <img src={"images/icons/arrow-right.svg"} alt="bitmap" style={{marginLeft: 10}} />
                            </div> */}
                        </div>
                    )
                })
            }
        </div>
      </div>
    </section>
  );
}
