import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import AppInput from '../../Components/AppInput';
import AppPhoneInput from '../../Components/AppPhoneInput';
import AppButton from '../../Components/AppButton';
import HrefLangHelmet from '../../Components/HrefLangHelmet';
import { NavLink } from 'react-router-dom';
import { contactForm } from '../../apis';
import ReCAPTCHA from "react-google-recaptcha";
import { statusCode } from '../../Helper/constants';
import mixpanel from 'mixpanel-browser';
import { toast } from "react-toastify";

const initState = {
  isLoading: false,
  first_name: "",
  last_name: "",
  full_name: "",
  email: "",
  mobile: "",
  company_name: "",
  reCaptcha: "",
  isContactUsForm: false
}

export default function DemoForm({toRoute}) {
  
  const reCaptchaRef = useRef(null)
  const {translations, lang} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  const [errors, setErrors] = useState([])
  const [state, setState] = useState(initState)


  const contactFormHandler = async()=>{
    
    
    try {
      setState({...state, isLoading: true})
      const recaptchaValue = await reCaptchaRef?.current?.executeAsync();
      let res = await contactForm({...state, reCaptcha: recaptchaValue},lang)
      res = JSON.parse(res)
      if(res.code === statusCode.K200){
        setState(initState)
        toast.success(res?.extra?.message ||  "",{
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true
        })
        if(res?.data?.room_id){
          window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/chat-v4?room_id=${res?.data?.room_id}`)
        }
        mixpanel.track("Appointment Form submitted successfully", {
          "language": "en_US",
          "page": "edhafah"
        });
      }else{
        setState({...state,isLoading: false})
        setErrors(res.errors);
        reCaptchaRef?.current?.reset();
      }
    } catch (error) {
      console.log(error, "DEMO PAGE ERROR")
      setState({...state, isLoading: false})
    }
    
  }


  const onChange = (field, value)=>{
    const _errors = errors
    if(errors[field]){
      delete _errors[field]
      setErrors(_errors)
    }
    setState({...state, [field]: value})
  }
  
  return (
    <>
      <HrefLangHelmet toRoute = {toRoute}/>
      <div className='demo-form'>
          <header className="header" id="header" style={{position: "sticky", minHeight: 43}}>
            <div className="auto__container">
              <div className="header__inner">
                <NavLink className="header__inner-logo" to={"/"}>
                  <img src={process.env.PUBLIC_URL + "/images/logo-demo.png"} alt="logo" style={{objectFit: 'contain', width: 'auto', borderRadius: 4}} />
                </NavLink>
                <nav className={"nav"} id="menu">
                  <span onClick = {()=>{}} className = {"clickable"}>
                    {/* <div className="row">
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                      <span style={{marginInlineStart: 10}} > {"Logout"}</span>
                    </div> */}
                  </span>
                </nav>
              </div>
            </div>
          </header>
          <div className='demo-page-form-center'>
            <div className="auto__container">
              <div className='demo-page-form'>
                <div className='demo-page-form-header'>
                  <h3>Book Your Appointment</h3>
                  <p>Fill out the details for informaion</p>
                </div>
                
                <AppInput
                        label={landingPageTranslations["landingPage.full_name"] || "Full Name"}
                        error = {errors?.full_name}
                        value = {state.full_name}
                        onChange = {(e)=>{onChange("full_name",e.target.value)}}
                      />
                <AppInput
                    label={landingPageTranslations["landingPage.email"] ||  "Email"}
                    error = {errors?.email}
                    value = {state.email}
                    type = {"email"}
                    onChange = {(e)=>{onChange("email",e.target.value)}}
                  />
                <AppInput
                      label={landingPageTranslations["landingPage.company_name"] ||  "Company Name"}
                      error = {errors?.company_name}
                      value = {state.company_name}
                      onChange = {(e)=>{onChange("company_name",e.target.value)}}
                    />
                <AppPhoneInput
                      name = {"mobile"}
                      value = {state.mobile}
                      onChange = {(number,country,e)=>{
                        if(e?.target?.value){
                          onChange("mobile",e.target.value)
                        }
                      }}
                      label = {landingPageTranslations["landingPage.mobile_number"] ||  "Mobile Number"}
                      error = {errors?.mobile}
                    />
                    <div className="signCheck" style={{marginTop: 10}}>
                    
                    <ReCAPTCHA
                        ref = {reCaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}
                        // onChange={(value)=>setState({...state,reCaptcha: value})}
                        size = {"invisible"}
                      />
                      {errors.reCaptcha? <span className = {"error__text"}>{errors.reCaptcha}</span> : null}
                    </div>
                  <AppButton isLoading={state.isLoading} title={landingPageTranslations["landingPage.submit"] ||  "Submit"} style={{width: "100%", marginTop: 20, borderColor: 'rgb(12,58,93)', backgroundColor: "rgb(12,58,93)"}}  onClick={contactFormHandler} />
              </div>
            </div>
        </div>    
      </div>
    </>
  )
}
