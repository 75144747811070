import React, { useEffect, useState } from "react";
import { tick } from "../../../assets/svgs";
import AppButton from "../../AppButton";
import { useSelector } from "react-redux";
// import NumberInput from "./NumberInput";


export default function Card({item,itemKey, toRoute}) {
    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}
  
  
  const [tab, setTab] = useState(null);
  const [value, setValue] = useState("");
  
  const [state, setState] = useState({
    selectedPlan: "monthly",
    numberOfUser: 1
  })

  
  useEffect(() => {
    
  }, [])
  

  
  /**
   * Handle Subscription Plan
   */

  const selectedPlanHandler = (type)=>{
    setState({...state, selectedPlan: type})
  }

  /**
   * Total Price of Selected Users
   */

//   const getTotal = ()=>{
//     const {numberOfUser, selectedPlan } = state 
//     localStorage.setItem("upgradePlanData",JSON.stringify({
//       numberOfUsers: numberOfUser,
//       selectedPlan: selectedPlan === "monthly"?1:12
//     }))
//     const price = selectedPlan === "monthly"? lookups?.data?.price_per_user_monthly: lookups?.data?.price_per_user_yearly
//     const total = selectedPlan === "monthly"? price * numberOfUser: price * 12 * numberOfUser
//     return total
//   }


  return (
    <div className="plan_card__inner">
        <div className="plan_card__header">
            <div className="row">
                <div className="plan_card_title">
                    {item.title}
                </div>
                <div className="plan_card_number">
                    <strong>{item.currency}{item.price}</strong> {item.per}
                </div>
            </div>
            <span className="most-popular-plan-container">
                {
                    item?.most_popular?
                        <>
                            <span>🔥</span>
                            <span className="most-popular-plan"> {landingPageTranslations["landingPage.most_popular"] || "Most popular"}</span>
                        </>
                    :<></>
                }
                </span>
        </div>
        <p className="plan_card_desc">{item.description}</p>
        <AppButton
            className="gradient"
            title={landingPageTranslations["landingPage.get_started"] || "Get Started"}
            style={{width: "100%"}}
            onClick = {()=>window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup?id=${itemKey}`)}
        />
        <div className="plan_card_points">
            <div className="plan_card_points-lable row">
                <div className="plan_card_points-left"></div>
                <label>{landingPageTranslations["landingPage.whats_included"] || "Whats Included?"}</label>
                <div className="plan_card_points-right"></div>
            </div>
            <ul>
                {
                    item?.features.map((point,key)=>{
                        return(
                            <li key={key} className = {"row"} style={{justifyContent: 'flex-start'}}>
                                <div>{tick}</div>
                                <div>{point}</div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    </div>
  );
}
