import React from 'react'
import Card from './Card'
import { useSelector } from 'react-redux';

export default function PricingPlan({data,toRoute}) {
    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}

  return (
    <section className="pricing-section-cards">
        <div className="auto__container">
            <div className="section-header">
                <h2>{landingPageTranslations["landingPage.unlock_limitless_growth"] || "Unlock Limitless Growth with Nunmu's Affordable Plans"}</h2>
                <p>{landingPageTranslations["landingPage.experience_the_full"] || "Experience the full capabilities of Nunmu risk-free with our no-obligation trial. Sign up now and start optimizing your sales process today!"}</p>
            </div>
            <div className='pricing-cards'>
                {
                    Object.keys(data || {}).map(cardKey=>{
                        const item = data[cardKey]
                        return(
                            <Card toRoute = {toRoute} key={cardKey} item={item} itemKey = {cardKey}/>
                        )
                    })
                }

            </div>
        </div>
    </section>
  )
}
