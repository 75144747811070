
import { configureStore } from '@reduxjs/toolkit'
import languageReducer from './features/language'
import lookupsReducer from './features/lookups'
import staticPageReducer from './features/staticPage/staticPageSlice'

export const store = configureStore({
  reducer: {
    language: languageReducer,
    lookups: lookupsReducer,
    staticPage: staticPageReducer,
  },
})