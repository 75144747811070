import React from 'react'
import { useSelector } from 'react-redux';
import ContactForm from '../../Components/ContactForm';
import Trial from '../Component/Trial';
import { useRef } from 'react';
import { useState } from 'react';
import AppButton from '../../Components/AppButton';
import HrefLangHelmet from '../../Components/HrefLangHelmet';
import mixpanel from 'mixpanel-browser';

const DealTracking = ({toRoute}) => {
    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}
    const formRef = useRef(null)
    const [isOpenForm, setIsOpenForm] = useState(false)

    return (
        <>
            <HrefLangHelmet toRoute = {toRoute}/>
            <div className='deal-tracking'>

                <div className="banner-header">
                    <div className="auto__container">
                        <div className='section-header'>
                            <h2 style={{textAlign: 'center'}} className="white">{landingPageTranslations["landingPage.deal_tracking_banner"] || "Maximize Your Sales Potential with Nunmu's Deal Tracking Feature"}</h2>
                            <AppButton 
                                onClick = {()=>{
                                    mixpanel.track("free trial clicked", {
                                        "language": "en_US",
                                        "section": "Banner",
                                        "page": "deal-tracking"
                                      });
                                    window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
                                }}
                                className='white' title={landingPageTranslations["landingPage.get_started"] || "Get Started"} style={{margin: "auto"}} />
                        </div>
                    </div>
                </div>

                <div className='deal-tracking-body'>
                    <div className="auto__container">
                        <div className='section-header'>
                            <h6>{landingPageTranslations["landingPage.deal_tracking_feature"] || "FEATURES"}</h6>
                            <h3 style={{color: "black"}}>{landingPageTranslations["landingPage.deal_tracking_feature_brief"] || "Nunmu helps sales teams keep track of their deal cycle, from winning new business to closing deals"}</h3>
                        </div>
                        <div className='deal-tracking-body-content'>
                            <div className='image-text-layout'>
                                <div className='image-text-layout-text-view'>
                                    <div className='heading'>{landingPageTranslations["landingPage.deal_tracking_feature_1_title"] || "Visualize Sales"}</div>
                                    <div className='p'>{landingPageTranslations["landingPage.deal_tracking_feature_1_brief"] || "Use the visual pipeline feature to efficiently track prospects through your sales funnel, and keep your team organized and focused on closing deals."}</div>
                                </div>
                                <div className='image-text-layout-image-view'>
                                    <img src={process.env.PUBLIC_URL + "/images/board-view.gif"} alt="logo" />
                                </div>
                            </div>
                            <div className='image-text-layout'>
                                <div className='image-text-layout-image-view'>
                                    <img src={process.env.PUBLIC_URL + "/images/myCrm.jpg"} alt="logo" />
                                </div>
                                <div className='image-text-layout-text-view'>
                                    <div className='heading'>{landingPageTranslations["landingPage.deal_tracking_feature_2_title"] || "Customize Deal Flow"}</div>
                                    <div className='p'>{landingPageTranslations["landingPage.deal_tracking_feature_2_brief"] || "Customize your sales process by creating deal stages to match your needs, and easily track the progress of each deal."}</div>
                                </div>
                            </div>
                            <div className='section-header'>
                                <h3 style={{color: "black"}}>{landingPageTranslations["landingPage.deal_track_footer_title"] || "Achieve Your Sales Targets"}</h3>
                                <h6 style={{margin: "30px 0"}}>{landingPageTranslations["landingPage.deal_track_footer_brief"] || "Track your sales targets effortlessly with Nunmu. Set, assign and track targets for individual team members and monitor their progress, providing insights and feedback to optimize sales performance."}</h6>
                            </div>
                            <div className='image-text-layout' style={{margin: 0}}>
                                <div className='image-text-layout-image-view'>
                                    <img src={process.env.PUBLIC_URL + "/images/revenue-growth.jpg"} alt="logo" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='manage-relationship'>
                    <ContactForm page={"deal-tracking"} isOpen ={isOpenForm} formRef= {formRef} btnText={isOpenForm === true? "": isOpenForm} source={isOpenForm === true? "": isOpenForm} />
                </div>
                <Trial page='deal-tracking' toRoute = {toRoute} setIsOpenForm = {()=>{
                    setIsOpenForm(landingPageTranslations["landingPage.submit_request"] ||  "Submit Request")
                    if(formRef){
                        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }
                }}/>

            </div>
        </>
    )
}

export default DealTracking
