import React from "react";
import { useSelector } from "react-redux";
import AppButton from "../../../Components/AppButton";
// import { landingPageTranslations } from "../../../translation";
import {angleRight} from '../../../assets/svgs'

export default function Favorite() {
  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  
  return (
    <section className="favorite">
      <div className="auto__container">
        <div className="favorite__inner">
          <div className="favorite__inner-content section-header">
            <label>{landingPageTranslations["landingPage.designed_for_dev"] || "Designed for developers"}</label>
            <h2 className="white">{landingPageTranslations["landingPage.integrate_with_your"] || "Integrate with your favorite apps."}</h2>
            <p className="white">{landingPageTranslations["landingPage.we_agonise"] || "We agonise over the right abstractions so your teams don’t need to stitch together disparate systems or spend months integrating payments functionality."}</p>
            <div className="favorite__inner-apps">
              <div className="favorite__inner-apps-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/partner/power-bi.svg"}
                  alt="svgs"
                />
              </div>
              <div className="favorite__inner-apps-item">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/partner/quick-books.svg"
                  }
                  alt="svgs"
                />
              </div>
              <div className="favorite__inner-apps-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/partner/slack.svg"}
                  alt="svgs"
                />
              </div>
              <div className="favorite__inner-apps-item">
                <img
                  src={process.env.PUBLIC_URL + "/images/partner/oracle.svg"}
                  alt="svgs"
                />
              </div>
            </div>
            <AppButton 
              disabled
              className="white" 
              // title={landingPageTranslations["landingPage.explore_our"] ||  "Explore Our Marketplace"}
              title={landingPageTranslations["landingPage.coming_soon"] || "Coming Soon"}
            >
              {/* <span className="flip">{angleRight}</span> */}
              {/* <img
                src={process.env.PUBLIC_URL + "/images/chevron-right.png"}
                alt="svgs"
              /> */}
            </AppButton>
            {/* <a href="/" className="button uniq" style={{marginTop: 25, backgroundColor: "#3A8ED0", borderColor: "#3A8ED0"}}>Explore market place</a> */}
          </div>
          <div className="favorite__inner-image">
            <img
              src={process.env.PUBLIC_URL + "/images/earth.png"}
              alt="svgs"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
