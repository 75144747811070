import { landingPageTranslations } from "../../../translation";

export const PlatformModul = [
  {
    key:  "landingPage.arbic_support",
    id: "1",
    name: "Arabic Support",
    image: "images/svgs/arabic.svg",
    textKey:  "landingPage.full_arabic",
    text: "Full Arabic support, allows for a native language interface and tailored content for users.",
    linkText: "Learn more about Interactive reports"
  },
  {
    key:  "landingPage.industry_driven",
    id: "2",
    name: "Industry Driven",
    image: "images/svgs/industry.svg",
    textKey:  "landingPage.pre_configured",
    text: "Pre-configured customizations tailored to specific industries, streamlining the setup process and optimizing it for your specific use case.",
    linkText: "Learn more about Interactive reports"
  },
  {
    key:  "landingPage.pre_built",
    id: "3",
    name: "Pre-Built Dashboards",
    textKey:  "landingPage.custom_manager",
    image: "images/svgs/chart.svg",
    text: "Custom manager reports and dashboards for quick, customizable insights to empower decision makers with actionable data.",
    linkText: "Learn more about Interactive reports"
  },
  {
    key:  "landingPage.integrations",
    id: "4",
    name: "Integrations",
    textKey:  "landingPage.empower_your_business",
    image: "images/svgs/integrations.svg",
    text: "Empower your business by connecting with other systems and vendors for enhanced functionality and data.",
    linkText: "Learn more about Interactive reports"
  }
];
