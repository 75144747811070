import React, { useEffect, useState, lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useNavigation } from "react-router-dom";
import Footer from './Base/Footer';
import Header from './Base/Header';
import countriesCode from '../Helper/countriesCode'

import LandingPage from './LandingPage'
import Pricing from './Pricing';
import AppMarket from './AppMarket';
import ContactManagement from './ContactManagement';
import StaticPage from './StaticPage'
import DealTracking from "./DealTracking"
import TaskManagement from"./TaskManagement"
import SalesInsight from"./SalesInsight"
import IndustryDriven from "./IndustryDriven"
import Page404 from './Page404'

import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGE_TYPE } from '../Helper/constants';
import { getCookie, setCookies } from '../Helper/functions';

import {Helmet} from "react-helmet-async";

import { getTranslationHandler } from '../redux/features/language';
import DemoForm from './DemoForm'




export default function AuthStack() {

  
  const {lookups,error} = useSelector(state => state.lookups);
  const location = useLocation();
  const {lang} = useSelector(state => state.language);
  // const param = location.pathname.replace(/\//g, '')
  const country = getCookie("country");
  
  const param = location.pathname.split("/")[1]
  const data  = param.split("-")
  const _lang = LANGUAGE_TYPE.en === lang?"en":"ar"
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [langRoute, setLangRoute] = useState(`${_lang}-${(data && data[1])? data[1].toLowerCase(): country?country:""}`)


  /**
   * Get Translation from server
   */
  useEffect(() => getTranslation(), [lang])
  const getTranslation = ()=>{
    dispatch(getTranslationHandler({
      category: 'common',
      lang: lang
    }))
  }
  

  useEffect(() => {
    if(error && !lookups?.data?.isoCode){
      if(country){
        const route = `${_lang}-${country}`
        setLangRoute(route)
        navigate(route,{replace: true})
      }
    }
  }, [error])
  
    
    

    useEffect(() => {
      if(!data[0] && !data[1]){
        const isoCode = lookups?.data?.isoCode.toLowerCase()
        if(isoCode){
          const route = `${_lang}-${isoCode}`
          setLangRoute(route)
          setCookies("country",isoCode,10000,`.${process.env.REACT_APP_DOMAIN}`)
          navigate(route,{replace: true})
        }
        return
      }

      // if((!data[0] || data[1] && !countriesCode.includes(data[1]))){
        // alert("404")
        // return
        // const route = `${_lang}-${_code}`
        // setLangRoute(route)
        // setCookies("country",_code,10000,`.${process.env.REACT_APP_DOMAIN}`)
        // navigate(route,{replace: true})
      // }
    }, [lookups?.data?.isoCode])
    
    const onLangChange = (lang)=>{
      if(lang !== _lang){
        const param = location.pathname.split("/")[1]
        const data  = param.split("-")
        const route = `${lang}-${data[1]}`
        const endpoint = location.pathname.indexOf("/", 1) > -1?location.pathname.slice(location.pathname.indexOf("/", 1)): ""
        // setLangRoute(route)
        // navigate(route + endpoint,{replace: true})
        window.location.replace(`/${route + endpoint}`)

        // window.location.replace(`loca${data[0]}-${lang}`)
      }
    }

    

    const hasDesc = !!lookups?.data?.metaTags[langRoute]?.description

  
    
    
    return (
    <>
      {
        param !== "edhafah"?    
        <Header onLangChange = {onLangChange} toRoute = {`${langRoute}`} />
        :
        <></>
      }
        {
          langRoute === "ar-iq"?
            <Helmet>
              <script type="application/ld+json">
                {`
                {
                  "@context": "https://schema.org",
                  "@id": "https://nunmu.com/#organization",
                  "@type": "Organization",
                  "name": "Nunmu",
                  "url": "https://nunmu.com/ar-iq",
                  "logo": "https://nunmu.com/images/logoLight.svg",
                  "description": ".تقًدم شركتنا حل أتمتة المبيعات ستجد هنا أفضل أدوات أتمتة المبيعات في العراق اتصل بنا الآن وزد مبيعاتك!",
                  "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "customer support",
                    "areaServed": "IQ"
                  },
                  "sameAs": ["https://www.linkedin.com/company/nunmumena/"]
                }
                `}
              </script>
            </Helmet>
          :
          langRoute === "ar-sa"?
            <Helmet>
              <script type="application/ld+json">
                {
                  `{
                    "@context": "https://schema.org",
                    "@id": "https://nunmu.com/#organization",
                    "@type": "Organization",
                    "name": "Nunmu",
                    "url": "https://nunmu.com/ar-sa",
                    "logo": "https://nunmu.com/images/logoLight.svg",
                    "description": "تقًدم شركتنا حل أتمتة المبيعات ستجد هنا أفضل أدوات أتمتة المبيعات في المملكة العربية السعودية اتصل بنا الآن وزد مبيعاتك!",
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "areaServed": "SA"
                    },
                    "sameAs": [
                      "https://www.linkedin.com/company/nunmumena/"
                    ]
                  }`
                }
              </script>
              
            </Helmet>
          :
          langRoute === "en-iq"?
          <Helmet>
            <script>
              {`
                {
                  "@context": "https://schema.org",
                  "@id": "https://nunmu.com/#organization",
                  "@type": "Organization",
                  "name": "Nunmu",
                  "url": "https://nunmu.com/en-iq",
                  "logo": "https://nunmu.com/images/logoLight.svg",
                  "description": "Our company offers a sales automation solution. Here you will find the best sales automation tools in Iraq. Contact us now and increase your sales!",
                  "contactPoint": {
                    "@type": "ContactPoint",
                    "contactType": "customer support",
                    "areaServed": "IQ"
                  },
                  "sameAs": [
                    "https://www.linkedin.com/company/nunmumena/"
                  ]
                }
              `}
            </script>
          </Helmet>
          :
          langRoute === "en-sa"?
          <Helmet>
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org",
                    "@id": "https://nunmu.com/#organization",
                    "@type": "Organization",
                    "name": "Nunmu",
                    "url": "https://nunmu.com/en-sa",
                    "logo": "https://nunmu.com/images/logoLight.svg",
                    "description": "Our company offers a sales automation solution. Here you will find the best sales automation tools in Saudi Arabia. Contact us now and increase your sales!",
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "areaServed": "SA"
                    },
                    "sameAs": [
                      "https://www.linkedin.com/company/nunmumena/"
                    ]
                  }
                `}
              </script>
            </Helmet>
          :
          <></>
        }
        {
          lookups?.data?.metaTags && lookups?.data?.metaTags[langRoute]?
          <Helmet>
            {
              lookups?.data?.metaTags[langRoute]?.keywords?
                <meta name="keywords" content={`${lookups?.data.metaTags[langRoute].keywords}`}></meta>
                :
                <></>
              }
            {
              hasDesc?
                <meta name="description" content={`${lookups?.data.metaTags[langRoute].description}`}></meta>
                :
                <></>
              }
            {lookups?.data?.metaTags[langRoute]?.title?
              <title>{lookups?.data.metaTags[langRoute].title || ""}</title>
              :<></>
            }

            {/* {lookups?.data?.metaTags[langRoute]?.description?
              // <meta name="description" content={`${lookups?.data.metaTags[langRoute].description}`}></meta>
              :<></>
            } */}
          </Helmet>
          :
          <></>
        }
        <div className='main'>
          {
            langRoute?
            <Routes>
              {
                countriesCode.includes((data && data[1])? data[1].toLowerCase(): country?country:"")?
                <Route path={`${langRoute}`}>
                  <Route index element={<LandingPage toRoute = {`${langRoute}`}/>} />
                    <Route  path="why-nunmu">
                        <Route index path="contact-management" element={<ContactManagement toRoute = {`${langRoute}`}/>} />
                        <Route path="app-market" element={<AppMarket toRoute = {`${langRoute}`} />} />
                        <Route path="deal-tracking" element={<DealTracking toRoute = {`${langRoute}`} />} />
                        <Route path="task-management" element={<TaskManagement toRoute = {`${langRoute}`} />} />
                        <Route path="sales-insight" element={<SalesInsight toRoute = {`${langRoute}`} />} />
                        <Route path="industry-driven" element={<IndustryDriven toRoute = {`${langRoute}`} />} />
                    </Route>
                  <Route path="pricing" element={<Pricing toRoute = {`${langRoute}`} />} />
                  <Route path="pages/:pageSlug" element={<StaticPage toRoute = {`${langRoute}`}/>} />
                  <Route path="*" element = {<Page404 toRoute = {`${langRoute}`}/>} />
                </Route>
                :
                <Route path="*" element = {<Page404 toRoute = {`${langRoute}`}/>} />

              }
              <Route path="*" element = {<Page404 toRoute = {`${langRoute}`}/>} />
              {/* <Route path="*" element={<Navigate to={`${langRoute}`} replace />}/> */}
              <Route path="edhafah" element={<DemoForm toRoute = {`${langRoute}`}/>}/>
              <Route path="/" element={<LandingPage/>}/>
              {/* <Route path="/" element={<Navigate to={`${langRoute}`} replace />}/> */}
                {/* </Route> */}
            </Routes>
            :
            <div className='sign'></div>
          }
        </div>
        {
          param !== "edhafah"?    
          <Footer toRoute = {`${langRoute}`} />
          :
          <></>
        }
    </>
  )
}


// const LandingIndex = ()=>(<Outlet/>)

