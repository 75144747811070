import React from "react";
import AppButton from "../../../Components/AppButton";
import { useSelector } from "react-redux";
import mixpanel from "mixpanel-browser";

export default function Trial({toRoute, setIsOpenForm, page = ""}) {
  
  const {translations, lang} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  
  return (
    <section className="trial">
      <div className="auto__container">
        <div className="trial__inner">
          <div className="section-header">
            <h2 className="white" >{landingPageTranslations["landingPage.start_your_free"] || "Start your free trial"}</h2>
            <p className="white">{landingPageTranslations['landingPage.no_credit'] || "No credit card required • Free for 30 days No commitment"}</p>
            
          </div>
          <div className="row">
            <AppButton
              title={landingPageTranslations["landingPage.contact_sales"] || "CONTACT SALES"}
              onClick={setIsOpenForm}
            />
            <AppButton
              title={landingPageTranslations["landingPage.free_trial"] || "FREE TRIAL"}
              className = {"white"}
              onClick = {()=>{
                mixpanel.track("free trial clicked", {
                  "language": "en_US",
                  "section": "footer banner",
                  "page": page
                });
                window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
              }}
            />
            
          </div>
        </div>
      </div>
    </section>
  );
}
