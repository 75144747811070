
const BASEURL = window.location.hostname === "localhost:3000"?"localhost:3000": process.env.REACT_APP_URL


const fileTypes = ["text/csv","text/xls","text/xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel"]
const statusCode = {
    S400: "S400",
    K200: "N200",
    K422: "N422", 
    K401: "N401",
    K409: "N409",
    K455: "N455",
    K404: "N404",
    K403: "N403",
    

}
const AVAILABLE_LANG = ["English", "Arabic"];
export const AVAILABLE_LANG_SRT = ["en", "ar"];
const LANGUAGE_TYPE = {
    en : "en-US",
    ar : "ar-AR"
}

const staticPageSlug = ["about","contact","benefits", "terms-of-service","privacy-policy"]

const mapLib = ["drawing","places"]
const tablePerPage = 5
const MyRefreshTokenVar = "qaseedabazzal"
const MyAccessTokenVar = "AZIASMDKASMDJKW"
// const editorButtons = ['bold', 'italic',"ul","ol","undo","redo", "fontsize","center", "left", "right", "justify", "image"]
const editorButtons = ['bold', 'italic',"ul","ol","undo","redo", "fontsize","align", "image"]
const editorButtons1 = ['bold', 'italic',"ul","ol","undo","redo","fontsize","align"]

const colors = {
    orange: "#D97706",
    green: "#0D9488",
    color: "#E11D48",
    gray: "#374151",
    blue: "#3581BD",
    red: "rgb(225, 29, 72)",
    text: "#344054"
}

const months = {
    "January": 1, 
    "February": 2, 
    "March": 3, 
    "April": 4, 
    "May": 5, 
    "June": 6, 
    "July": 7,
    "August": 8,
    "September": 9, 
    "October": 10, 
    "November": 11,
    "December": 12
}
const quaters = {
    Q1:"Q1",
    Q2:"Q2",
    Q3:"Q3",
    Q4:"Q4"
}
const currentYear = new Date().getFullYear()

const mixpanelActions = {
    QUICK_ADD: (lang)=>({
        "event": "quick add menu triggered",
        "properties": {
          "language": lang
        }
    }),
    ADD_A_CONTACT_TRIGGERED: (lang)=>({
        "event": "add a contact triggered",
        "properties": {
          "language": lang
        }
    }),
    ADD_A_COMPANY_TRIGGERED: (lang)=>({
        "event": "add a company triggered",
        "properties": {
          "language": lang,
        }
    }),
    ADD_A_DEAL_TRIGGERED: (lang,section = "quick add", type = "")=>({
        "event": "add a deal triggered",
        "properties": {
          "language": lang,
          "section": section,
          "account type": type
        }
    }),
    ADD_A_MEETING_TRIGGERED: (lang, section = "quick add", type)=>({
        "event": "add a meeting triggered",
        "properties": {
          "language": lang,
          "section": section,
          "account type": type
        }
    }),
    ADD_A_TASK_TRIGGERED: (lang, section = "quick add", type)=>({
        "event": "add a task triggered",
        "properties": {
          "language": lang,
          "section": section,
          "account type": type
        }
    }),
    ADD_A_CALL_TRIGGERED: (lang, section = "quick add", type)=>({
        "event": "add a call triggered",
        "properties": {
          "language": lang,
          "section": section,
          "account type": type
        }
    }),
    TOOLS_MENU_CLICKED: (lang)=>({
      "event": "tools menu triggered",
      "properties": {
        "language": lang
      }
  }),
    USER_MENU_CLICKED: (lang)=>({
        "event": "user menu clicked",
        "properties": {
          "language": lang
        }
    }),
    MANAGE_YOUR_USERS_TRIGGERED: (lang)=>({
        "event": "manage your users triggered",
        "properties": {
          "language": lang
        }
    }),
    CREATE_YOUR_PRODUCTS_TRIGGERED: (lang)=>({
        "event": "create your products triggered",
        "properties": {
          "language": lang
        }
    }),
    SET_TARGETS_TRIGGERED: (lang)=>({      
        "event": "set targets triggered",
        "properties": {
            "language": lang
        }
    }),
    SET_GOALS_TRIGGERED: (lang)=>({
        "event": "set goals triggered",
        "properties": {
          "language": lang
        }
    }),
    LABEL_TRANSLATION_TRIGGERED: (lang)=>({
        "event": "label translation triggered",
        "properties": {
          "language": lang
        }
    }),
    YOUR_CRM_TRIGGERED: (lang)=>({
        "event": "your crm triggered",
        "properties": {
          "language": lang
        }
    }),
    LANGUAGE_MENU_CLICKED: (lang)=>({
        "event": "language menu clicked",
        "properties": {
          "language": lang
        }
    }),
    
    ADVANCE_SEARCH: (lang)=>({
        "event": "advanced search triggered",
        "properties": {
          "language": lang
        }
    }),
    MAP_SEARCH_TRIGGERED: (lang)=>({
        "event": "map search triggered",
        "properties": {
          "language": lang
        }
    }),
    SEARCH_TRIGGERED: (lang,query)=>({
        "event": "search triggered",
        "properties": {
          "language": lang,
          "query": query
        }
    }),
    DASHBOARD_TRIGGERED: (lang)=>({
        "event": "dashboard clicked",
        "properties": {
          "language": lang
        }
    }),
    CONTACTS_TRIGGERED: (lang)=>({
        "event": "contacts clicked",
        "properties": {
          "language": lang
        }
    }),
    COMPANIES_TRIGGERED: (lang)=>({
        "event": "companies clicked",
        "properties": {
          "language": lang
        }
    }),
    DEALS_TRIGGERED: (lang)=>({
        "event": "deals clicked",
        "properties": {
          "language": lang
        }
    }),
    MEETINGS_TRIGGERED: (lang)=>({
        "event": "meetings clicked",
        "properties": {
          "language": lang
        }
    }),
    CALLS_TRIGGERED: (lang)=>({
        "event": "calls clicked",
        "properties": {
          "language": lang
        }
    }),
    TASKS_TRIGGERED: (lang)=>({
        "event": "tasks clicked",
        "properties": {
          "language": lang
        }
    }),
    TOOLS_TRIGGERED: (lang)=>({
        "event": "tools clicked",
        "properties": {
          "language": lang
        }
    }),
    NAV_MENU_TRIGGERED: (lang)=>({
        "event": "nav menu triggered",
        "properties": {
          "language": lang
        }
    }),
    DELETE_SAMPLE_DATA_TRIGGERED: (lang)=>({
        "event": "delete sample data triggered",
        "properties": {
          "language": lang
        }
    }),
    DELETE_SAMPLE_DATA_TRIGGERED_ON_SUCCESS: (lang)=>({
        "event": "delete sample data completed",
        "properties": {
          "language": lang
        }
    }),
    SETTINGS_TRIGGERED: (lang)=>({
      "event": "settings triggered",
      "properties": {
        "language": lang
      }
    }),
    USER_LOGOUT_TRIGGERED_ON_SUCCESS: (lang)=>({
      "event": "user log out",
      "properties": {
        "language": lang
      }
    }),
    //DASHBOARD ACTIONS
    DASHBOARD_TAB_SWITCHED: (lang, activeTab)=>({
      "event": "dashboard tab switched",
      "properties": {
        "language": lang,
        "activeTab": activeTab
      }
    }),
    ADD_DASHBOARD_TAB_TRIGGERED: (lang)=>({
      "event": "add dashboard tab triggered",
      "properties": {
        "language": lang
      }
    }),
    DASHBOARD_TAB_TRIGGERED_ON_SUCCESS: (lang,tab_name)=>({
      "event": "dashboard tab added",
      "properties": {
        "language": lang,
        "tabName": tab_name
      }
    }),
    DASHBOARD_TAB_CANCELLED: (lang)=>({
      "event": "add dashboard tab cancelled",
      "properties": {
        "language": lang
      }
    }),
    ADD_DASHBOARD_WIDGET_TRIGGERED: (lang)=>({
      "event": "add dashboard widget triggered",
      "properties": {
        "language": lang
      }
    }),
    ADD_DASHBOARD_WIDGET_TRIGGERED_ON_SUCCESS: (lang, widgetName)=>({
      "event": "dashboard widget added",
      "properties": {
        "language": lang,
        "widgetName": widgetName
      }
    }),
    DASHBOARD_WIDGET_REMOVED_ON_SUCCESS: (lang, widgetName)=>({
      "event": "dashboard widget removed",
      "properties": {
        "language": lang,
        "widgetName": widgetName
      }
    }),
    CONTACT_ADD_ON_SUCCESS: (lang,section = "quick add")=>({
      "event": "contact added successfully",
      "properties": {
        "language": lang,
        "section": section
      }
    }),
    COMPANY_ADD_ON_SUCCESS: (lang,section = "quick add")=>({
      "event": "company added successfully",
      "properties": {
        "language": lang,
        "section": section
      }
    }),
    DEALS_ADD_ON_SUCCESS: (lang,section = "quick add",type)=>({
      "event": "deal added successfully",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type || section
      }
    }),
    MEETING_ADD_ON_SUCCESS: (lang,section = "quick add")=>({
      "event": "meeting added successfully",
      "properties": {
        "language": lang,
        "section": section
      }
    }),
    CALL_ADD_ON_SUCCESS: (lang,section = "quick add")=>({
      "event": "call added successfully",
      "properties": {
        "language": lang,
        "section": section
      }
    }),
    TASK_ADD_ON_SUCCESS: (lang,section = "quick add")=>({
      "event": "TASK added successfully",
      "properties": {
        "language": lang,
        "section": section
      }
    }),
    ACCOUNT_CLIKED: (lang,type,element)=>({
      "event": "account clicked",
      "properties": {
        "language": lang,
        "element": element,
        "type": type
      }
    }),
    ACCOUNT_TAB_CLIKED: (lang,type,element)=>({
      "event": "account tab clicked",
      "properties": {
        "language": lang,
        "element": element,
        "type": type
      }
    }),
    ACCOUNT_INFO_EDIT_TRIGGERED: (lang,type)=>({
      "event": "account info edit triggered",
      "properties": {
        "language": lang,
        "type": type
      }
    }),
    CONTACT_FORM_EDIT: (lang,field, fieldGroup, action = "field-edit")=>({
      "event": "contact info form edited",
      "properties": {
        "language": lang,
        "field": field || fieldGroup,
        "fieldGroup": fieldGroup,
        "action": action
      }
    }),
    CONTACT_INFO_ABORTED: (lang)=>({
      "event": "contact info form aborted",
      "properties": {
        "language": lang
      }
    }),
    CONTACT_INFO_EDIT_ON_SUCCESS: (lang)=>({
      "event": "contact info edited successfully",
      "properties": {
        "language": lang,
      }
    }),
    COMPANY_FORM_EDIT: (lang,field, fieldGroup, action = "field-edit")=>({
      "event": "company info form edited",
      "properties": {
        "language": lang,
        "field": field || fieldGroup,
        "fieldGroup": fieldGroup,
        "action": action
      }
    }),
    COMPANY_INFO_ABORTED: (lang)=>({
      "event": "company info form aborted",
      "properties": {
        "language": lang
      }
    }),
    COMPANY_INFO_EDIT_ON_SUCCESS: (lang)=>({
      "event": "company info edited successfully",
      "properties": {
        "language": lang,
      }
    }),
    DEAL_TAB_CHANGED: (lang,section,type,tab)=>({
      "event": "deal tab changed",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
        "deal tab": tab,
      }
    }),
    DEAL_TAB_SKIPPED: (lang,section,type,tab)=>({
      "event": "deal tab skipped",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
        "deal tab": tab,
      }
    }),
    DEAL_FORM_CLOSED: (lang,section,type)=>({
      "event": "deal form closed",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
      }
    }),
    SALES_STAGE_UPDATED_ON_SUCCESS: (lang,section,type,stage)=>({
      "event": "deal stage updated successfully",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
        "sales stage": stage,
      }
    }),
    SALES_STAGE_CLOSED_ON_SUCCESS: (lang,section,type,stage)=>({
      "event": "deal closed successfully",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
        "sales stage": stage,
      }
    }),
    CUSTOMIZE_COLUMN_TRIGGERED: (lang,section,type,sections)=>({
      "event": "customize columns triggered",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
        "sections": sections,
      }
    }),
    DEAL_ACTION_TRIGGERED: (lang,section,type,sections,action_type)=>({
      "event": "deal action triggered",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
        "sections": sections,
        "action type": action_type,
      }
    }),
    MODIFY_DEAL_TRIGGERED: (lang,section,type)=>({
      "event": "modify deal triggered",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
      }
    }),
    DEAL_DETAILS_MODAL_CLOSED: (lang,section,type)=>({
      "event": "deal details modal closed",
      "properties": {
        "language": lang,
        "section": section,
        "account type": type,
      }
    }),
    
    
}


export {
    mixpanelActions,
    currentYear,
    months,
    quaters,
    statusCode,
    LANGUAGE_TYPE,
    staticPageSlug,
    BASEURL,
    mapLib,
    MyRefreshTokenVar,
    MyAccessTokenVar,
    tablePerPage,
    editorButtons,
    editorButtons1,
    colors,
    AVAILABLE_LANG,
    fileTypes
}