import React from 'react'
import ContactForm from '../../Components/ContactForm'
import ContactManagementHeader from './ContactManagementHeader'
import ManageRelationship from './ManageRelationship'
import { useState } from 'react'
import HrefLangHelmet from '../../Components/HrefLangHelmet'

export default function ContactManagement({toRoute}) {
  
  return (
    <>
      <HrefLangHelmet toRoute = {toRoute}/>
      <ContactManagementHeader toRoute ={toRoute}/>
      <ManageRelationship toRoute ={toRoute}/>
      <ContactForm page={"contact-management"} />
    </>
  )
}
