import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ContactForm from '../../Components/ContactForm'
import PricingPlan from '../../Components/PricingPlan'
import { statusCode } from '../../Helper/constants'
import { getPricingCardsApi } from '../../networking'
import ChatSection from '../Component/ChatSection'
import Trial from '../Component/Trial'
import HrefLangHelmet from '../../Components/HrefLangHelmet'

export default function Pricing({toRoute}) {


    const [data, setData] = useState([])
    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}
    const [isOpenForm, setIsOpenForm] = useState(false)

    const formRef = useRef(null)
    
    useEffect(() => {
        getPricingCardsHandler()
    }, [lang])
    
    const getPricingCardsHandler = async()=>{
        let res = await getPricingCardsApi({lang:lang});
        res = JSON.parse(res)
        if(res.code === statusCode.K200){
            setData(res.data)
        }
    }

  return (
    <>
        <HrefLangHelmet toRoute = {toRoute}/>
        <PricingPlan data = {data} toRoute = {toRoute}/>
        <ChatSection
            heading = {landingPageTranslations["landingPage.donot_hesitate_to_reach"] || "Don't hesitate to reach out - our support team is here to guide you in selecting the perfect plan for your needs"}
            buttonLable = {landingPageTranslations["landingPage.live_chat"] || "LIVE CHAT"}
            onPress = {()=>{window?.groove?.widget?.open()}}
        />
        <ContactForm page={"pricing"} isOpen ={isOpenForm} formRef= {formRef} btnText={isOpenForm === true? "": isOpenForm} source={isOpenForm === true? "": isOpenForm} />
        <Trial page='pricing' toRoute = {toRoute}  setIsOpenForm = {()=>{
            setIsOpenForm(landingPageTranslations["landingPage.submit_request"] ||  "Submit Request")
            if(formRef){
                formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
        }}/>
    </>
  )
}
