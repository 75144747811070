import React from 'react'
import { searchIcon } from '../../assets/svgs'

export default function AppSearchInput({style = {},value, name, isDisable, onChange, placeholder}) {
  return (
    <div className='search-input'>
        {searchIcon}
        <input
            style={style}
            placeholder={ placeholder} 
            type={"search"} 
            value={value} 
            onChange = {onChange} 
            disabled = {isDisable}
            name = {name}
        />
    </div>
  )
}
