import React, { useEffect } from 'react'
import Api from './Api';
import Favorite from './Favorite';
import Feature from './Feature';
import Intro from './Intro'
import Platform from './Platform';
import ContactForm from '../../Components/ContactForm'
import { Outlet } from 'react-router-dom';
import HrefLangHelmet from '../../Components/HrefLangHelmet';
import mixpanel from 'mixpanel-browser';

export default function LandingPage({toRoute}) {

  // useEffect(() => {
  //   if(mixpanel){
  //     try {
  //       mixpanel.track("landing page",{
  //         "language": toRoute || ""
  //       });
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // }, [])


  return (
    <>
      <HrefLangHelmet toRoute = {toRoute}/>
      <Intro toRoute = {toRoute}/>
      <Platform toRoute = {toRoute}/>
      <Favorite toRoute = {toRoute}/>
      <Feature toRoute = {toRoute}/>
      <Api toRoute = {toRoute}/>
      <ContactForm page={"landingPage"} />
      <Outlet toRoute = {toRoute}/>
    </>
  )
}
