import React from 'react';
import Header from './Header'  
import HrefLangHelmet from '../../Components/HrefLangHelmet';

const Page404= ({toRoute}) =>{
    return (
        <>
        <HrefLangHelmet toRoute = {toRoute}/>
        {/* <Header/> */}
        <div className="sign">      
            {/* <div className="sign__inner"> */}
                <div style={{color: "#fff"}}>
                    <h1>404 Error</h1>
                    <h1>Page Not Found</h1>
                </div>
            {/* </div> */}
        </div>
        </>
    )
}
  
export default Page404;