import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { contactForm } from '../../apis'
import AppButton from '../AppButton'
import AppInput from '../AppInput'
import ReCAPTCHA from "react-google-recaptcha";
import {statusCode} from '../../Helper/constants'


import { toast } from "react-toastify";
import AppPhoneInput from '../AppPhoneInput'
import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'

export default function ContactForm({source = "",btnText = "",isOpen = false, formRef = null, showHeader = true, page}) {

  // const {lang} = useSelector(state => state.language)
  const {translations, lang} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  const [isFormShow, setIsFormShow] = useState(false)
  const reCaptchaRef = useRef(null)
  const [state, setState] = useState({
    isLoading: false,
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    company_name: "",
    reCaptcha: "",
    full_name: ""
  })

  const [errors, setErrors] = useState({})


  useEffect(() => {
    if(isOpen){
      setIsFormShow(true)
    }
  }, [isOpen])
  
  

  const contactFormHandler = async()=>{
    try {
      setState({...state, isLoading: true})
      let recaptchaValue = await reCaptchaRef?.current?.executeAsync();
      let res = await contactForm({...state, reCaptcha: recaptchaValue},lang)
      res = JSON.parse(res)
      if(res.code === statusCode.K200){
        toast.success(res?.extra?.message ||  "",{
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true
        })
        mixpanel.track("get demo submitted successfully", {
          "language": "en_US",
          "page": page
        });
        setState({
          isLoading: false,
          full_name: "",
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          company_name: "",
          reCaptcha: ""
        })
      }else{
        setState({...state,isLoading: false})
        setErrors(res.errors);
        reCaptchaRef?.current?.reset();
      }
    } catch (error) {
      setState({...state, isLoading: false})
    }
    
  }

  const onChange = (field, value)=>{
    const _errors = errors
    if(errors[field]){
      delete _errors[field]
      setErrors(_errors)
    }
    setState({...state, [field]: value})
  }


  return (
    <>
    <div className='contact-form' ref={formRef}>
        <div className="auto__container">
            <div className='contact-form__inner'>
                {
                  showHeader?
                  <div className='section-header'>
                    <p>{landingPageTranslations["landingPage.try_nunmu_now"] || "Try Nunmu Now!"}</p>
                    <p>{landingPageTranslations['landingPage.no_credit'] || "No credit card required • Free for 30 days No commitment"}</p>
                    <h2>{landingPageTranslations["landingPage.unleash_your_business"] || "Unleash Your Business Growth with Seamless Sales Automation"}</h2>
                    {
                      !isFormShow?
                        <AppButton
                          // title={isFormShow? "Hide Form":"Contact us"}
                          title={landingPageTranslations["landingPage.get_demo"] || "Get Demo"}
                          style = {{margin: "auto", width: 246}}
                          onClick = {()=>{
                            mixpanel.track("get demo clicked", {
                              "language": "en_US",
                              "page": page
                            });
                            
                            setIsFormShow(!isFormShow)
                          }}
                        />
                      :
                      <></>
                    }
                  </div>
                  :
                  <></>
                }
                
                <div className={`contact-form-auth ${isFormShow?"active": ""}`}>
                      <AppInput
                        label={landingPageTranslations["landingPage.full_name"] || "Full Name"}
                        error = {errors?.full_name}
                        value = {state.full_name}
                        onChange = {(e)=>{onChange("full_name",e.target.value)}}
                      />
                    {/* <div className='row' style={{alignItems: 'flex-start'}}>
                      <AppInput
                        label={landingPageTranslations["landingPage.first_name"] || "First Name"}
                        error = {errors?.first_name}
                        value = {state.first_name}
                        onChange = {(e)=>{onChange("first_name",e.target.value)}}
                      />
                        <AppInput
                          label={landingPageTranslations["landingPage.last_name"] ||  "Last Name"}
                          error = {errors?.last_name}
                          value = {state.last_name}
                          onChange = {(e)=>{onChange("last_name",e.target.value)}}
                        />
                    </div> */}
                    <AppInput
                      label={landingPageTranslations["landingPage.email"] ||  "Email"}
                      error = {errors?.email}
                      value = {state.email}
                      type = {"email"}
                      onChange = {(e)=>{onChange("email",e.target.value)}}
                    />
                    <AppPhoneInput
                      name = {"mobile"}
                      value = {state.mobile}
                      onChange = {(number,country,e)=>{
                        if(e?.target?.value){
                          onChange("mobile",e.target.value)
                        }
                      }}
                      label = {landingPageTranslations["landingPage.mobile_number"] ||  "Mobile Number"}
                      error = {errors?.mobile}
                    />
                    {/* <AppInput
                      label={"Mobile Number"}
                      error = {errors?.mobile}
                      value = {state.mobile}
                      onChange = {(e)=>{onChange("mobile",e.target.value)}}
                      /> */}
                    <AppInput
                      label={landingPageTranslations["landingPage.company_name"] ||  "Company Name"}
                      error = {errors?.company_name}
                      value = {state.company_name}
                      onChange = {(e)=>{onChange("company_name",e.target.value)}}
                    />
                    <div className="signCheck" style={{marginTop: 10}}>
                    
                    <ReCAPTCHA
                        ref = {reCaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}
                        // onChange={(value)=>setState({...state,reCaptcha: value})}
                        size = {"invisible"}
                      />
                      {errors.reCaptcha? <span className = {"error__text"}>{errors.reCaptcha}</span> : null}
                    </div>
                    {/* landingPageTranslations["landingPage.submit"] ||  "Submit" */}
                    <AppButton
                      isLoading = {state.isLoading}
                      title={btnText? btnText : landingPageTranslations["landingPage.start_your_demo_trial"] ||  "Start Your Demo Trial" }
                      onClick = {contactFormHandler}
                      btnProp={{
                        "data-source": source?source: "get-demo"
                      }}
                    />
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
