import React from 'react'
import AppButton from '../../../Components/AppButton'
import { landingPageTranslations } from '../../../translation'

export default function ChatSection({onPress,buttonLable,heading }) {
  return (
    <div className='chat-section'>
        <div className="auto__container">
            <div className='chat-section__inner'>
                <h3 className='white'>{heading}</h3>
                <AppButton
                    title={buttonLable}
                    onClick = {onPress}
                />
            </div>
        </div>
    </div>
  )
}