import React, { useRef, useState, useEffect } from 'react';
import AppButton from '../../Components/AppButton';
import { useSelector } from 'react-redux';
import { angleRight } from '../../assets/svgs';
import ContactForm from '../../Components/ContactForm';
import Trial from '../Component/Trial';
import HrefLangHelmet from '../../Components/HrefLangHelmet';
import mixpanel from 'mixpanel-browser';
// import './styles.css';

function TaskManagement({toRoute}) {
    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}
    const formRef = useRef(null)
    const [isOpenForm, setIsOpenForm] = useState(false)
    const [activeSection, setActiveSection] = useState(1);
    const sectionRefs = [
      useRef(null),
      useRef(null),
      useRef(null)
    ];

    useEffect(() => {
        const options = {
          rootMargin: '-200px 0px -200px 0px',
          threshold: 0.5
        };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setActiveSection(parseInt(entry.target.dataset.section));
            }
          });
        }, options);

        sectionRefs.forEach((sectionRef) => {
          if (sectionRef.current !== null) {
            observer.observe(sectionRef.current);
          }
        });

        return () => {
          sectionRefs.forEach((sectionRef) => {
            if (sectionRef.current !== null) {
              observer.unobserve(sectionRef.current);
            }
          });
        };
      }, []);

  return (
    <>
        <HrefLangHelmet toRoute = {toRoute}/>
        <div className='task-management'>
            <div class="banner-header">
                <div class="auto__container">
                    <div class ={"api__inner"}>
                        <div className='api__inner-content'>
                            <h2>{landingPageTranslations["landingPage.task_management_header_title"] || "Manage Tasks with Ease"}</h2>
                            <p style={{marginBottom: 40, marginTop: 10}}>{landingPageTranslations["landingPage.task_management_header_brief"] || "Streamline Your Workflow and Never Miss a Deadline"}</p>
                            <AppButton
                                title={landingPageTranslations['landingPage.start_your_free'] || "START YOUR FREE TRIAL NOW"}
                                className = {"transparent"}
                                onClick = {()=>{
                                    mixpanel.track("free trial clicked", {
                                        "language": "en_US",
                                        "section": "banner",
                                        "page": "task-management"
                                    });
                                    window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
                                }}
                            />
                            <p style={{marginTop: 16}}>{landingPageTranslations['landingPage.no_credit'] || "No credit card required • Free for 30 days No commitment"}</p>
                        </div>
                        <div className='api__inner-image'>
                        <img src={process.env.PUBLIC_URL + "/images/task-management.jpg"} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='deal-tracking-body'>
                <div className="auto__container">
                    <div className='section-header'>
                        <h3 style={{color: 'black'}}>
                            {landingPageTranslations["landingPage.task_management_title"] || "With Nunmu, you can easily create, assign, and track tasks to ensure that your team stays on top of their deadlines and delivers quality work."}
                        </h3>
                    </div>
                    <div className='paralex-scrolling'>
                        <div className='paralex-scrolling-content'>
                            <section className={`section ${activeSection === 1 ? 'active' : ''}`} data-section="1" ref={sectionRefs[0]}>
                                <div className='section-wrapper'>
                                    <div className='image-text-layout-text-view'>
                                        <div className='heading' >
                                            {landingPageTranslations["landingPage.task_management_1_title"] || "Create Tasks Quickly"}
                                        </div>
                                        <div className='p' >
                                            {landingPageTranslations["landingPage.task_management_1_brief"] || "Nunmu's intuitive interface allows you to create new tasks in just a few clicks. Simply input the task details, assign it to a team member, and set the due date."}
                                        </div>
                                    </div>
                                    <div className='image-text-layout-image-view'>
                                        <img src={process.env.PUBLIC_URL + "/images/tm1.jpg"} alt="logo" />
                                    </div>
                                </div>
                            </section>
                            <section className={`section ${activeSection === 2 ? 'active' : ''}`} data-section="2" ref={sectionRefs[1]}>
                                <div className='section-wrapper'>
                                    <div className='image-text-layout-text-view'>
                                        <div className='heading'>{landingPageTranslations["landingPage.task_management_2_title"] || "Set Task Priorities"}</div>
                                        <div className='p'>{landingPageTranslations["landingPage.task_management_2_brief"] || "Prioritizing tasks can be a challenge, but with Nunmu's Task Management, you can easily mark a task as high, medium, or low priority. This ensures that your team is focusing on the most important tasks first."}</div>
                                    </div>
                                    <div className='image-text-layout-image-view'>
                                        <img src={process.env.PUBLIC_URL + "/images/tm2.jpg"} alt="logo" />
                                    </div>
                                </div>
                            </section>
                            <section className={`section ${activeSection === 3 ? 'active' : ''}`} data-section="3" ref={sectionRefs[2]}>
                                <div className='section-wrapper'>
                                    <div className='image-text-layout-text-view'>
                                        <div className='heading'>{landingPageTranslations["landingPage.task_management_3_title"] || "Monitor Progress with Task Reports"}</div>
                                        <div className='p'>{landingPageTranslations["landingPage.task_management_3_brief"] || "With Nunmu's Task Management, you can easily track the progress of your team's tasks and generate reports to see how you're doing. This helps you identify areas for improvement and make data-driven decisions to enhance your workflow."}</div>
                                    </div>
                                    <div className='image-text-layout-image-view'>
                                        <img src={process.env.PUBLIC_URL + "/images/tm3.jpg"} alt="logo" />
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className='paralex-scrolling-images'>
                            <img className={`${activeSection === 1?"active":""}`} src={process.env.PUBLIC_URL + "/images/tm1.jpg"} alt="logo" />
                            <img className={`${activeSection === 2?"active":""}`} src={process.env.PUBLIC_URL + "/images/tm2.jpg"} alt="logo" />
                            <img className={`${activeSection === 3?"active":""}`} src={process.env.PUBLIC_URL + "/images/tm3.jpg"} alt="logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manage-relationship'>
                <ContactForm page={"task-management"} isOpen ={isOpenForm} formRef= {formRef} btnText={isOpenForm === true? "": isOpenForm} source={isOpenForm === true? "": isOpenForm} />
            </div>
            <Trial page='task-management' toRoute = {toRoute}  setIsOpenForm = {()=>{
                setIsOpenForm(landingPageTranslations["landingPage.submit_request"] ||  "Submit Request")
                if(formRef){
                    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
            }}/>
        </div>
    </>
  );
}

export default TaskManagement;
