import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import ContactForm from '../../Components/ContactForm';
import Trial from '../Component/Trial';
import { useRef } from 'react';
import { useState } from 'react';
import AppButton from '../../Components/AppButton';
import { industriesListApi } from '../../apis';
import { statusCode } from '../../Helper/constants';
import ChatSection from '../Component/ChatSection';
import HrefLangHelmet from '../../Components/HrefLangHelmet';

const IndustryDriven = ({toRoute}) => {

    const {translations, lang} = useSelector(state => state.language);
    const landingPageTranslations = translations?.landingPage || {}
    const formRef = useRef(null)
    const [isOpenForm, setIsOpenForm] = useState(false)
    const [industriesList, setIndustriesList] = useState({
        data: []
      })

    useEffect(() => {
        getIndustriesHandler()
      }, [lang])

    const getIndustriesHandler  = async()=>{
        try {
          let res  = await industriesListApi(lang)
          res =JSON.parse(res)
          if(res.code === statusCode.K200){
            setIndustriesList({data:res.data})
          }
        } catch (error) {

        }
      }


    return (
        <>
            <HrefLangHelmet toRoute = {toRoute}/>
            <div className='industry-driven deal-tracking'>
                <div className='deal-tracking-body'>
                    <div className="auto__container">
                        <div className='section-header'>
                            <h2 style={{color: "black"}}>
                                {landingPageTranslations["landingPage.industry_driven_title"] || "Accelerate Your Setup with Customized Templates for Your Industry"}</h2>
                            <p>{landingPageTranslations["landingPage.industry_driven_brief"] || "Nunmu is the only sales management platform that is industry-driven, meaning it comes pre-customized with templates that cater to your specific industry needs. This allows you to accelerate your setup phase and get started with sales management quickly."}</p>
                            {/* <h6>FEATURES</h6> */}
                        </div>
                        <div className='industry-driven-body-content'>
                            <div className='section-header'>
                                <div className="report-cards-container">
                                    {
                                        industriesList?.data.map(item=>(
                                        <div class={`report-card clickable`}>
                                            <div class="report-card-image">
                                                <img src={item.image}/>
                                            </div>
                                            <div class="report-card-body">
                                                <h3>{item.label}</h3>
                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatSection
                    heading = {landingPageTranslations["landingPage.get_started_with_nunmu_today_and_take"] || "Get started with Nunmu today and take advantage of our industry-driven customization to optimize your sales process and accelerate your business growth."}
                    buttonLable = {landingPageTranslations["landingPage.live_chat"] || "LIVE CHAT"}
                    onPress = {()=>{window?.groove?.widget?.open()}}
                />
                <div className='manage-relationship'>
                    <ContactForm page={"industry-driven"} isOpen ={isOpenForm} formRef= {formRef} btnText={isOpenForm === true? "": isOpenForm} source={isOpenForm === true? "": isOpenForm} />
                </div>
                <Trial page='industry-driven' toRoute = {toRoute}  setIsOpenForm = {()=>{
                    setIsOpenForm(landingPageTranslations["landingPage.submit_request"] ||  "Submit Request")
                    if(formRef){
                        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }
                }}/>

            </div>
        </>
    )
}

export default IndustryDriven
