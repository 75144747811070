import React from "react";
import { useSelector } from "react-redux";
import { angleRight } from "../../../assets/svgs";
import AppButton from "../../../Components/AppButton";
// import { landingPageTranslations } from "../../../translation";
import FeatureItem from "./FeatureItem";
import { FeatureModul } from "./FeatureModul";
import mixpanel from "mixpanel-browser";

export default function Feature({toRoute}) {
  const {translations} = useSelector(state => state.language);
  const landingPageTranslations = translations?.landingPage || {}
  
  return (
    <section className="feature">
      <div className="auto__container">
        <div className="feature__inner">
          <div className="section-header">
            <p style={{marginBottom: 5}}>{landingPageTranslations["landingPage.unlock_the_full"] ||  "Unlock the Full Potential of Your Business with NUNMU's powerful Features"}</p>
            <h2>{landingPageTranslations["landingPage.all_in_one_solution"] ||  "All-in-one solution that helps teams manage, track, and streamline their sales deals!"}</h2>
          </div>
          <div className="feature__inner-row">
            {FeatureModul.map((FeatureItems) => {
              const translationText  = landingPageTranslations[FeatureItems.key]
              return (
                <FeatureItem
                  translationText = {translationText}
                  key={FeatureItems.id}
                  {...FeatureItems}
                />
              );
            })}
          </div>
          <AppButton
            title={landingPageTranslations["landingPage.start_your_free"] || "Start Your Free Trial Now"}
            className = {"white"}
            style = {{marginTop: 30}}
            onClick = {()=>{
              mixpanel.track("free trial clicked", {
                "language": "en_US",
                "section": "Features",
                "page": "landing page"
              });
              window.location.replace(`${process.env.REACT_APP_REDIRECT_URL}/${toRoute}/signup`)
            }}
          >
            <span className="flip">{angleRight}</span>
          </AppButton>
        </div>
      </div>
    </section>
  );
}
