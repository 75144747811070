import React from "react";

export default function FeatureItem(props) {
  return (
    <div className="featureCard">
      <div className="featureCard__image">
        <img src={props.image} alt="bitmap" />
      </div>
      <div className="featureCard__body">
        <h5>
          <span>{props.number}</span>
          {props.translationText ||  props.text}
        </h5>
      </div>
    </div>
  );
}
