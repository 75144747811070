const baseURL = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_VERSION

export const getDefaultPageApi = async({slug, lang})=>{
  
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  }
  const response = await fetch(`${baseURL}/site/default/page?slug=${slug}&lang=${lang}`, requestOptions)
  return response.text()

}

export const getTranslation = async(type, category)=>{
  
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  }
  const response = await fetch(`${baseURL}/utilities/default/translation?lang=${type}&category=${category}`, requestOptions)
  return response.text()

}

export const utilitiesLookupApi = async(data)=>{
  
  const requestOptions = {
    method: 'GET',
    redirect: 'follow'
  }
  const response = await fetch(`${baseURL}/utilities/default/lookup`, requestOptions)
  return response.text()
}

export const contactForm = async(data, lang)=>{
  
  const myHeaders = new Headers();
  const formdata = new FormData();
  
  // formdata.append("first_name", data.first_name);
  // formdata.append("last_name", data.last_name);
  formdata.append("full_name", data.full_name);
  formdata.append("email", data.email);
  formdata.append("mobile", `${data.mobile.replace(/[-\s_]/g, "")}`);
  formdata.append("company_name", data.company_name);
  formdata.append("reCaptcha", data.reCaptcha);
  formdata.append("lang", lang);
  {
    data?.isContactUsForm === false?
      formdata.append("isContactUsForm", 0)
    :
    <></>
  }

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };
  
  const response = await fetch(`${baseURL}/site/default/contact-us`, requestOptions)
  return response.text()

}


export const industriesListApi = async(lang)=>{
  
  const requestOptions = {
    method: 'GET',
    // body: formdata,
    redirect: 'follow'
  };
  const response = await fetch(`${baseURL}/auth/default/industries-list?lang=${lang}`, requestOptions)
  return response.text()
  
}