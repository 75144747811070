import React from "react";
import { useSelector } from "react-redux";

import { NavLink } from "react-router-dom";

export default function Footer({toRoute}) {
  
  const {translations, lang} = useSelector(state => state.language);
  const {lookups} = useSelector(state => state.lookups);
  const landingPageTranslations = translations?.landingPage || {}
  const commonTranslation = translations?.common || {}
  
  

  return (
    <footer className="footer">
      <div className="auto__container">
      <div className="copyright">
          <div className="copyright__text">
            {
              lang === "ar-AR"?
                `© ${new Date().getFullYear()} ${commonTranslation["common.developedByKinz"] || "Developed by KINZ. All rights reserved."}`
                :
                `${commonTranslation["common.developedByKinz"] || "Developed by KINZ. All rights reserved."} © ${new Date().getFullYear()}`
            }
          </div>
          <div>
            {
              lookups?.data?.social_icons?
              <div className="social-icons">
                {
                  Object.keys(lookups?.data?.social_icons || {}).map(_icon=>{
                    const item = lookups?.data?.social_icons[_icon]
                    return(
                      <a key={item.icon} href={item.link} target="blank">
                        <i className={`${item.icon}`}></i>
                      </a>
                    )
                  })
                }
              </div>
              :
              <></>
            }
            <div className="copyright__nav">
              <NavLink to={`/${toRoute}/pages/terms-of-service`} className="copyright__nav-link">
                {commonTranslation["common.terms"] || "Terms"}
              </NavLink>
              <NavLink to={`/${toRoute}/pages/privacy-policy`} className="copyright__nav-link">
                {commonTranslation["common.privacy"] || "Privacy"}
              </NavLink>
              {/* <NavLink to={`/${toRoute}/pages/about`} className="copyright__nav-link">
                {commonTranslation["common.about"] || "about"}
              </NavLink> */}
              <NavLink to={`/${toRoute}/pages/contact`} className="copyright__nav-link">
                {commonTranslation["common.contact"] || "contact"}
              </NavLink>
              
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
