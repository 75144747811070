import React, { useState } from 'react'
import { useEffect } from 'react';
import {Helmet} from "react-helmet-async";
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
// import { LANGUAGE_TYPE } from '../../Helper/constants';
// import { getCookie } from '../../Helper/functions';


export default function HrefLangHelmet({toRoute = ""}) {
  const {lookups,error} = useSelector(state => state.lookups);
  const currentUrl = window.location.href;
  const hasTrailingSlash = currentUrl.endsWith('/');
  const updatedUrl = hasTrailingSlash ? currentUrl.slice(0, -1) : currentUrl;

  const [pageMeta, setPageMeta] = useState(false)
  
  useEffect(() => {
    if(lookups?.data && lookups?.data?.metaTags[toRoute] && lookups?.data?.metaTags[toRoute]?.pages){
      let removedString = window.location.pathname.replace(new RegExp("^" + `/${toRoute}/`), "");
      setPageMeta(lookups?.data?.metaTags[toRoute].pages[removedString] || false)
      
    }
    
  }, [lookups?.data])
  


  
  return (
    <>
      {
        hasTrailingSlash?
        <Helmet>
          <link rel="canonical" href={updatedUrl}></link>
        </Helmet>
        :
        <></>
      }
      {
        pageMeta?
          <Helmet>
            {
              pageMeta?.keywords?
                <meta name="keywords" content={pageMeta?.keywords}></meta>
                :
                <></>
              }
            {
              pageMeta?.description?
                <meta name="description" content={pageMeta?.description}></meta>
                :
                <></>
              }
            {pageMeta?.title?
              <title>{pageMeta?.title || ""}</title>
              :<></>
            }

            {/* {lookups?.data?.metaTags[langRoute]?.description?
              // <meta name="description" content={`${lookups?.data.metaTags[langRoute].description}`}></meta>
              :<></>
            } */}
          </Helmet>
          :
          <></>
      }
    </>
  )
  
  
}


// export default function HrefLangHelmet() {
//   // No need to add tag here, we used sitemap.xml
//   const param = window.location.pathname.split("/")[1]
//   const alr = param.includes("ar")?param.replace("ar","en"):param.replace("en","ar")
  
  
//   return (
//     <Helmet>
//       <link rel="canonical" hreflang={`${alr}`} href={window.location.href.replace(param,alr)}></link>
//     </Helmet>
//   )
// }
