import React, { useState } from 'react'

export default function AppInput({name = "",left="",type ="text",isDisable = false,label, error, value, onChange, className = "", placeholder = ""}) {
  const [isShow, setIsShow] = useState(false)
  return (
    <div className={`signInput ${className}`}>
        {
          (label && <label htmlFor="">{label}</label>)
        }
        <div className="signInput__inner">
          {left}
          <div style={{position: 'relative'}}>
            <input
              className={`${isDisable?"disable-input":""}`}
              placeholder={ placeholder || label || ""} 
              type={isShow?"text":type} 
              value={value} 
              onChange = {onChange} 
              disabled = {isDisable}
              name = {name}
              // autoComplete="off"
              aria-orientation='top'
            />
            {
              type === "password"?
              <i className="pass-icon far fa-eye" onMouseUp = {()=>setIsShow(false)} onMouseDown = {()=>setIsShow(true)}></i>
              :<></>
            }
          </div>
          {error? <div className = {"error__text"}>{error}</div> : null}
        </div>
    </div>
  )
}
