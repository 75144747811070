import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { getDefaultPageApi } from '../../../apis';
import { statusCode } from '../../../Helper/constants';

//Get default Page

export const getDefaultPage  = createAsyncThunk('default/page', async (data, thunkAPI)=>{
    try {
        let res = await getDefaultPageApi(data);
        res   = JSON.parse(res);
        
        const hasErrors = Object.keys(res?.errors || {})
        if(res.Status === statusCode.S400){
            return thunkAPI.rejectWithValue("SERVER ERROR");
        }
        if(res.code === statusCode.K200){
            return res.data
        }else if(hasErrors.length > 0){
            return thunkAPI.rejectWithValue(res.errors);
        }


    } catch (error) {
        const message = error?.response?.data?.message || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})


// initial state
const initialState = {
    
    isLoading: false,
    isSuccess: false,
    data: {},
    message: ""
}



export const  staticPageSlice = createSlice({
    name: "staticPage",
    initialState,
    
    extraReducers: (builder)=>{
        builder
            .addCase(getDefaultPage.pending, (state)=>{
                state.isLoading = true
            })
            .addCase(getDefaultPage.fulfilled, (state, {payload})=>{
                
                state.isLoading =  false
                state.isSuccess = true
                state.data      = {
                    ...state.data,
                    [`${payload.slug}`]: payload
                }
                
                
            })
            .addCase(getDefaultPage.rejected,(state, action)=>{
                
                state.isLoading = false
                state.isSuccess = false
                state.message   = action.payload

            })
            
    }
})


export default  staticPageSlice.reducer